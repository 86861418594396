import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { InputForm } from '@components';
import ic_search from '@assets/icons/ic_search.svg';
import { UserCollapse } from './UserCollapse';
import Inner from '@assets/icons/Inner.svg';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import logo_edit from '@assets/icons/edit.svg';
import { ChatCenter } from './ChatCenter';

import { useTranslation } from 'react-i18next';

const MainWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 1px 0px #edf1f8;
  border-radius: 16px;
  width: 98%;
  height: 88%;
  padding: 0px 10px 10px 0px;
  margin: 12px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  position: absolute;
`;

const Row = styled.div`
  flex-direction: row;
  height: 45px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #edf1f8;

  &::-webkit-scrollbar {
    height: 8px;
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    border-radius: 1rem;
  }

  &::-webkit-scrollbar-thumb {
    background: ${PALETTE_COLOR.FIELD_BORDER_COLOR};
    border-radius: 1rem;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${PALETTE_COLOR.FIELD_BORDER_COLOR};
  }
`;

const ChatColumn = styled.div`
  flex-direction: column;
  flex: 1;
  display: flex;
  height: 100%;
  border-width: 1px;
  border-right-style: solid;
  border-right-color: #edf1f8;
`;

const MessageColumn = styled.div`
  flex-direction: column;
  flex: 3;
  display: flex;
  height: 100%;
`;

const Separator = styled.div`
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #edf1f8;
  margin-left: 12px;
  margin-right: 12px;
`;

const Icon = styled.img``;

const NoDataPanelTitle = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: ${PALETTE_COLOR.BLACK};
`;

const NoDataPanelText = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  text-align: center;
  color: ${PALETTE_COLOR.MIDDLE_GRAY};
`;

const NoDataPanelContainer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
`;

const TitleRoomChat = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;
`;

const TitleUserChat = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

const RoomName = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

const RoomCanChat = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
`;

const RowChat = styled.div`
  flex-direction: row;
`;

export const Chat = () => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const [totals, setTotals] = useState({ totalA: 1, totalR: 0, totalE: 0 });
  const [chatSelected, setChatSelected] = useState();

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  /***
   *
   */
  function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
      () => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
          setDebouncedValue(value);
          setTotals({ totalA: 1, totalR: 0, totalE: 0 });
        }, delay);
        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
          clearTimeout(handler);
        };
      },
      [value, delay], // Only re-call effect if value or delay changes
    );
    return debouncedValue;
  }

  return (
    <MainWrapper>
      <ChatColumn>
        <Row>
          <InputForm
            style={{ width: '100%', background: 'white' }}
            height={'40px'}
            placeholder={t('placeholder_search')}
            icon={ic_search}
            ctype={'remove'}
            setSearchTerm={setSearchTerm}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Row>

        {totals.totalA + totals.totalE === 0 ? (
          <NoDataPanelContainer>
            <Icon src={Inner} style={{ width: '42px', height: '42px', marginBottom: '20px' }} />
            <NoDataPanelTitle>{t('MessageCenter.chat.nothing_found')}</NoDataPanelTitle>
            <NoDataPanelText>{t('MessageCenter.chat.try_another')}</NoDataPanelText>
          </NoDataPanelContainer>
        ) : (
          <Row style={{ flex: '1', overflowY: 'scroll' }}>
            <UserCollapse
              type={'Administrators'}
              search={debouncedSearchTerm}
              setTotals={setTotals}
              setChatSelected={setChatSelected}
              chatSelected={chatSelected}
            />
            <Separator />
            <UserCollapse
              type={'Employees'}
              search={debouncedSearchTerm}
              setTotals={setTotals}
              setChatSelected={setChatSelected}
              chatSelected={chatSelected}
            />
          </Row>
        )}
      </ChatColumn>
      <MessageColumn>
        <Row>
          {chatSelected?.type === 'Rooms' ? (
            <TitleRoomChat>
              <RoomCanChat>{t('MessageCenter.chat.can_chat')}</RoomCanChat>
              <RoomName>{`${chatSelected?.name}`}</RoomName>
              <Icon src={logo_edit} alt="edit icon" />
            </TitleRoomChat>
          ) : (
            <TitleUserChat>{`${chatSelected?.name ? chatSelected?.name : ''}`}</TitleUserChat>
          )}
        </Row>
        <RowChat>
          <ChatCenter destinationUser={chatSelected} />
        </RowChat>
      </MessageColumn>
    </MainWrapper>
  );
};
