import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { getEntityData, editEntityData } from 'services';
import { USERS_API } from '@services/CONSTANTS';
import { Container, Row, Col, ListGroup, Card, Button } from 'react-bootstrap';
import styled from 'styled-components';
import restore from '@assets/icons/ic_restore.svg';
import EncView from '@components/EncView';
import { AppModalStatus } from '@components/AppModalStatus';
import { EditEmplyUserForm } from '../EditEmplyUserForm';
import { RestoreEmplyUserForm } from '../RestoreEmplyUserForm';
import { StateTag, SendInviteBtn, HeaderBtn, AppModal, AppButton, AppOrgIcon } from '@components';
import status_active from '@assets/icons/status_active.svg';
import status_blocked from '@assets/icons/Status_block.svg';
import status_invited from '@assets/icons/Invited.svg';
import status_draft from '@assets/icons/Draft.svg';
import Archived from '@assets/icons/Archived.svg';
import ic_organizations from '@assets/icons/ic_organizations.svg';
import ico_Archived from '@assets/icons/ico_Archived.svg';
import ico_Block from '@assets/icons/ico_Block.svg';
import ico_Restore from '@assets/icons/ico_Restore.svg';
import ic_note from '@assets/icons/ic_note.svg';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import AppPanel from '@components/AppPanel';
import logo_edit from '@assets/icons/edit.svg';
import { formatFullDate, formatDate } from '@utils/dateFormatHelper';
import { CustomizedSnackbar } from 'components/CustomizedSnackbar';
import { Menu, MenuItem } from '@szhsin/react-menu';
import Button_Outline from '@assets/icons/Button_Outline.svg';
import IconButton from '@mui/material/IconButton';
import { useSelector } from 'react-redux';
import ic_send_invite from '@assets/icons/ic_send_invite.svg';
import send_message from '@assets/icons/ic_message.svg';
import { AssignOrganizationForm } from './AssignOrganizationForm';
import { EditAssignOrganizationForm } from './EditAssignOrganizationForm';
import { NoteForm } from './NoteForm';
import ic_add_circle from '@assets/icons/ic_add_circle.svg';
import { CondOperator } from '@nestjsx/crud-request';
import { calcAvatar } from 'utils/avatar';
import Lottie from 'lottie-react';
import groovyWalkAnimation from '@assets/animations/72798-skeleton.json';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { NoteCard } from './NoteCard';
import { EMPLOYEES_NOTES_API } from 'services/CONSTANTS';
import { DASHBOARD } from '@navigation/CONSTANTS';

import { useTranslation } from 'react-i18next';

const SpacButtonRestore = styled(IconButton)`
  background: rgb(255, 255, 255);
  border: 1px solid rgb(231, 236, 244);
  box-sizing: border-box;
  border-radius: 20px;
  height: 40px;
  padding: 7px 7px 7px 7px;
  border: 1px solid #e7ecf4;
  padding: 7px 7px 7px 7px;
  margin-left: 2%;
`;

const StyledNestedCell = styled.div`
  align-items: center;
  font-size: 14px;
  padding: 0px 15px;
  margin: 4px;
  border-radius: 10px;
  height: 46px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const DropDownWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
`;

const Icon = styled.img``;

const LinkAction = styled(Button)`
  font-size: 13px;
  text-decoration: none;
  text-align: center;
  border-radius: 20px;
  color: ${PALETTE_COLOR.MAIN_BLUE};
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  :hover,
  :focus {
    border: 1px solid rgba(131, 180, 49, 0.2);
    box-sizing: border-box;
    color: ${PALETTE_COLOR.MAIN_BLUE};
    box-shadow: unset;
  }
`;

const StyleDivTitle = styled.div`
  bottom: 80.54%;
  border-radius: 52px;
  margin-right: 9px;
  margin-left: -15px;
  font-size: 20px;
  font-family: 'Poppins';
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 3px solid #ffffff;
  box-sizing: border-box;
  display: flex;
  text-align: center;
  align-content: center;
  justify-content: center;
  align-items: center;
`;

const SpaceEncName = styled.div`
  display: flex;
  justify-content: start;
  width: 100%;
  align-items: center;
`;

const SpaceEnc = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  align-items: center;
`;

const StyleRow = styled.div`
  padding: 14px 44px 24px;
`;

const StyleTittle = styled(Row)`
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  color: ${PALETTE_COLOR.LIGHT_GREY};
`;

const StyleFields = styled(Row)`
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: ${PALETTE_COLOR.BLACK};
`;

const StyleColContent = styled(Col)`
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 19.5px;
  color: ${PALETTE_COLOR.BLACK};
`;

const StyledLabel = styled.div`
  margin-left: 22px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  font-family: 'Poppins';
  display: flex;
`;

const NoDataPanelTitle = styled.h3`
  font-size: 21px;
  font-weight: 400;
  line-height: 31px;
  text-align: center;
  color: ${PALETTE_COLOR.BLACK};
  margin-bottom: unset;
`;

const NoDataPanelText = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: ${PALETTE_COLOR.MIDDLE_GRAY};
`;

const NoDataPanelWrapper = styled.p`
  margin-top: 60px;
  margin-bottom: 60px;
`;

const NotesWrapper = styled.div`
  width: 100%;
  padding: 10px 20px 10px 20px;
  margin-top: 10px;
`;

const NotesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
`;

const NotesHeaderColumn = styled.div`
  flex-direction: column;
`;

const NotesHeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const NotesHeaderTitle = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 32px;
  color: #000000;
`;

const Edit = styled.img`
  width: 12.59px;
  height: 16px;
`;

export const ViewEmployeeUser = () => {
  const { t } = useTranslation();
  let { userId } = useParams();
  let history = useHistory();
  const [openRestoreUser, setOpenRestoreUser] = React.useState(false);
  const useSelectorData = useSelector((state) => state.app);
  const { valuesDefinitions, userPermissions } = useSelectorData;
  const userLogged = useSelectorData.userData;
  //Avoid white page
  const newStatusActive = React.useMemo(() => {
    let result = [];
    if (valuesDefinitions) {
      result = valuesDefinitions.filter(
        (v) => v.definitionType.definition_type === 'USER_STATUS' && v.value_definition === '010',
      )[0]; //Active
    }
    return result;
  }, [valuesDefinitions]);
  //Avoid white page
  const newStatusBlocked = React.useMemo(() => {
    let result = [];
    if (valuesDefinitions) {
      result = valuesDefinitions.filter(
        (v) => v.definitionType.definition_type === 'USER_STATUS' && v.value_definition === '050',
      )[0]; //Blocked
    }
    return result;
  }, [valuesDefinitions]);
  //Avoid white page
  const newStatusArchive = React.useMemo(() => {
    let result = [];
    if (valuesDefinitions) {
      result = valuesDefinitions.filter(
        (v) => v.definitionType.definition_type === 'USER_STATUS' && v.value_definition === '060',
      )[0]; //Archived
    }
    return result;
  }, [valuesDefinitions]);

  const [openChangeStatusUser, setOpenChangeStatusUser] = React.useState(false);
  const [openEditUserForm, setOpenEditUserForm] = React.useState(false);
  const [reload, setReload] = React.useState(true);
  // TODO: Remove reloadTable; it is not being used.
  const [reloadTable, setReloadTable] = React.useState(true);
  const [newUserStatus, setNewUserStatus] = useState();
  const [userData, setUserData] = useState();
  const [userStatusModalContent, setUserStatusModalContent] = useState({
    actionIcon: '',
    title: '',
    subtitle: '',
    subject: '',
    bgcolor: '',
    bordercolor: '',
    borderhovercolor: '',
    bghovercolor: '',
    boxShadow: '',
    buttonText: '',
  });
  //Loading var
  const [loading, setLoading] = useState(false);

  const getGroups = (userData) => {
    let groupsIDs = [0];
    if (userData && userData.user_groups?.length > 0) {
      userData.user_groups.forEach((ug) => {
        groupsIDs.push(ug.group.id);
      });
    }
    return groupsIDs;
  };
  // TODO: remove useMemo filters var, it is not being used
  //Mandatory Filters for consulting organizations
  let filters = useMemo(
    () => [
      { field: 'user_id.id', operator: CondOperator.EQUALS, value: userId },
      {
        field: 'organizationUserSitesSiteGroupsSiteGrupo.id',
        operator: CondOperator.IN,
        value: getGroups(userData),
      },
    ],
    [userData, userId],
  );

  const joins = useMemo(
    () => [
      { field: 'organization_user_sites', select: ['site'] },
      {
        field: 'organization_user_sites.site',
        select: ['name', 'address'],
      },
      {
        field: 'organization_user_sites.site.groupsSite',
      },
      {
        field: 'organization_user_sites.site.groupsSite.group',
      },
      { field: 'organization_id.organizations_sites' },
      { field: 'organization_id.organizations_sites.status' },
      {
        field: 'organization_id.organizations_sites.groupsSite',
        select: ['group'],
      },
      {
        field: 'organization_id.organizations_sites.groupsSite.group',
        select: ['name'],
      },
    ],
    [],
  );

  //Enable buttons
  const [enableButton, setEnableButton] = useState(true);

  const handleCloseChangeStatusUser = () => {
    setOpenChangeStatusUser(false);
  };
  const handleClickOpenSystemFormModal = () => {
    setOpenEditUserForm(true);
  };
  const handleClickOpenRestoreUserModal = () => {
    setOpenRestoreUser(true);
  };
  const handleCloseRestoreUser = () => {
    setOpenRestoreUser(false);
  };
  const handleCloseSystemFormModal = () => {
    setOpenEditUserForm(false);
  };
  const [showSucessModal, setShowSucessModal] = React.useState({
    show: false,
    type: '',
    title: '',
    message: '',
  });

  //Controls if assigned org modal is shown or not
  const [openAssignedOrgForm, setOpenAssignedOrgForm] = React.useState(false);

  //Controls if edit assigned org modal is shown or not
  const [openEditAssignOrgForm, setOpenEditAssignOrgForm] = React.useState(false);

  //Store th org to be edited
  const [orgToEdit, setOrgToEdit] = React.useState();

  //Controls if invite modal is shown or not
  const [openSendInvite, setOpenSendInvite] = React.useState(false);

  //Controls if re-sent invitation modal is shown or not
  const [openReSendInvite, setOpenReSendInvite] = React.useState(false);

  //Show or not org assigned
  // TODO: Remove hasOrganizations, it is not being used
  const [hasOrganizations, setHasOrganizations] = useState(false);

  //Store notes
  const [employeeNotes, setEmployeeNotes] = useState([]);

  //Loading notes
  const [isLoadingNotes, setIsLoadingNotes] = useState(false);

  //Reload user notes
  const [reloadEmployeeNotes, setReloadEmployeeNotes] = React.useState(true);

  //Controls if add note modal is shown or not
  const [openAddNoteForm, setOpenAddNoteForm] = React.useState(false);

  //Content to show in sent invitation modal
  const sendInvitationModalData = {
    buttonText: `${t('buttons.btn_send')}`,
    actionIcon: ic_send_invite,
    title: '',
    subtitle: `${t('Users.invitation_message')}`,
    subtitleWeight: 500,
    subtitleColor: PALETTE_COLOR.ACCENT_GREEN,
    subjectColor: '#000000',
    subjectWeight: 300,
    bgcolor: PALETTE_COLOR.ACCENT_PURPLE,
    bordercolor: PALETTE_COLOR.ACCENT_PURPLE,
    borderhovercolor: PALETTE_COLOR.ACCENT_PURPLE_LIGHT,
    bghovercolor: PALETTE_COLOR.ACCENT_PURPLE_LIGHT,
    boxShadow: '0px 4px 10px rgba(137, 137, 255, 0.3)',
  };

  const isSiteManager = userLogged.role?.value_definition !== '020';
  //Content to show in re-sent invitation modal
  const resendInvitationModalData = {
    buttonText: `${t('buttons.btn_send')}`,
    actionIcon: ic_send_invite,
    title: '',
    subtitle: `${t('Users.resend_invitation_message')}`,
    subtitleWeight: 500,
    subtitleColor: PALETTE_COLOR.ACCENT_GREEN,
    subjectColor: '#000000',
    subjectWeight: 300,
    bgcolor: PALETTE_COLOR.ACCENT_PURPLE,
    bordercolor: PALETTE_COLOR.ACCENT_PURPLE,
    borderhovercolor: PALETTE_COLOR.ACCENT_PURPLE_LIGHT,
    bghovercolor: PALETTE_COLOR.ACCENT_PURPLE_LIGHT,
    boxShadow: '0px 4px 10px rgba(137, 137, 255, 0.3)',
  };

  /***
   * Depend on which groups system user has and its permissions, and which groups org has,
   * show or hide buttons
   */
  const showButtonsToSystemUser = React.useCallback(
    (empInfo) => {
      let showButtons = false;
      //Get employee groups
      const empGroups = empInfo?.user_groups.map((userGroup) => userGroup?.group?.id);
      if (empGroups.length > 0) {
        for (let i = 0; i < userPermissions?.systemUserGroups?.length; i++) {
          const systemUserGroup = userPermissions?.systemUserGroups[i];
          if (empGroups.includes(systemUserGroup.id) && systemUserGroup.permission === '020') {
            showButtons = true;
            break;
          }
        }
      }
      return showButtons;
    },
    [userPermissions],
  );

  /***
   * Get user by id
   */
  useEffect(() => {
    if (reload) {
      const qb = RequestQueryBuilder.create().setFilter({
        field: 'id',
        operator: CondOperator.EQUALS,
        value: userId,
      });
      //Call API
      getEntityData(USERS_API, qb.query())
        .then((res) => {
          setUserData(res[0]);
          // console.log('USER DATA', res[0]);
          setReload(true);
          setHasOrganizations(res[0].organizations.length > 0);
          //If it is a system user, determine if he can edit org or add sites/employees
          if (userPermissions?.type === 'system') {
            setEnableButton(showButtonsToSystemUser(res));
          }
          setReloadTable(true);
        })
        .catch((err) => {
          console.log('axios err=', err);
        });
      return () => {
        console.log('axios cleanup.');
      };
    }
  }, [userId, showSucessModal, reload, showButtonsToSystemUser, userPermissions]);

  /***
   * Get employee notes
   */
  useEffect(() => {
    if (reloadEmployeeNotes) {
      setIsLoadingNotes(true);
      const qb = RequestQueryBuilder.create()
        .setJoin({ field: 'user' })
        .setJoin({ field: 'author' })
        .setFilter({ field: 'user.id', operator: CondOperator.EQUALS, value: userId });
      //Call API
      getEntityData(EMPLOYEES_NOTES_API, qb.query())
        .then((res) => {
          setEmployeeNotes(res);
          setIsLoadingNotes(false);
          setReloadEmployeeNotes(false);
        })
        .catch((err) => {
          console.log('axios err=', err);
          setIsLoadingNotes(false);
        });
      return () => {
        console.log('axios cleanup.');
      };
    }
  }, [userId, showButtonsToSystemUser, userPermissions, reloadEmployeeNotes]);

  /***
   * Check site manager permissions
   */
  useEffect(() => {
    //Redirect if SM does not belong to same site
    if (userLogged.isSiteManager && userLogged.sites) {
      if (
        userLogged?.sites?.filter(
          (s) => userData?.sites.findIndex((userSite) => userSite.id === s.id) !== -1,
        ).length === 0
      ) {
        history.push(DASHBOARD);
      }
    }
  }, [history, userLogged, userData]);

  /***
   *
   */
  const changeStatusUser = () => {
    setLoading(true);
    setShowSucessModal((prev) => {
      return { ...prev, show: false };
    });
    console.log('requestChangeSiteStatus ', { status: newUserStatus });
    editEntityData(`${USERS_API}${userData.id}`, { status: newUserStatus })
      .then((res) => {
        setShowSucessModal({
          show: true,
          type: 'success',
          title: `${t('success_messages.user_status_changed')}`,
          message: '',
        });
        setLoading(false);
        handleCloseChangeStatusUser();
      })
      .catch((err) => {
        setLoading(false);
        handleCloseChangeStatusUser();
        setShowSucessModal({
          show: true,
          type: 'error',
          title: `${t('error_messages.error_user_status_changing')}`,
          message: '',
        });
      });
  };

  /***
   *
   */
  const handleClickOpenChangeStatusSite = (newStatus) => {
    setOpenChangeStatusUser(true);
    setNewUserStatus(newStatus);
    switch (newStatus.value_definition) {
      case '060':
        setUserStatusModalContent({
          buttonText: `${t('buttons.btn_archive')}`,
          actionIcon: ico_Archived,
          title: `${t('Users.modals.archive_title')}`,
          subtitle: `${t('Users.modals.archive_subtitle')}`,
          bgcolor: PALETTE_COLOR.ACCENT_RED,
          bordercolor: PALETTE_COLOR.ACCENT_RED,
          borderhovercolor: PALETTE_COLOR.ACCENT_RED_LIGHT,
          bghovercolor: PALETTE_COLOR.ACCENT_RED_LIGHT,
          boxShadow: '0px 4px 10px rgba(255, 85, 85, 0.3)',
        });
        break;
      case '050':
        setUserStatusModalContent({
          buttonText: `${t('buttons.btn_block')}`,
          actionIcon: ico_Block,
          title: `${t('Users.modals.block_title')}`,
          subtitle: `${t('Users.modals.block_subtitle')}`,
          bgcolor: PALETTE_COLOR.ACCENT_ORANGE,
          bordercolor: PALETTE_COLOR.ACCENT_ORANGE,
          borderhovercolor: PALETTE_COLOR.ACCENT_ORANGE_LIGHT,
          bghovercolor: PALETTE_COLOR.ACCENT_ORANGE_LIGHT,
          boxShadow: '0px 4px 10px rgba(255, 124, 50, 0.3)',
        });
        break;
      case '010':
        setUserStatusModalContent({
          buttonText: `${t('buttons.btn_unblock')}`,
          actionIcon: ico_Restore,
          title: `${t('Users.modals.unblock_title')}`,
          subtitle: `${t('Users.modals.unblock_subtitle')}`,
          bgcolor: PALETTE_COLOR.ACCENT_GREEN,
          bordercolor: PALETTE_COLOR.ACCENT_GREEN,
          borderhovercolor: PALETTE_COLOR.ACCENT_GREEN_LIGHT,
          bghovercolor: PALETTE_COLOR.ACCENT_GREEN_LIGHT,
          boxShadow: '0px 4px 10px rgba(134, 217, 113, 0.3)',
        });
        break;
      default:
        break;
    }
  };

  /***
   * Show/close modal to send invite to draft users
   */
  const handleSendInviteModal = () => {
    setOpenSendInvite((prev) => {
      return !prev;
    });
  };

  /***
   * Show/close modal to re-send invite
   */
  const handleReSendInviteModal = () => {
    setOpenReSendInvite((prev) => {
      return !prev;
    });
  };

  /***
   * Send invitation to user and change its state
   */
  const sendInvite = () => {
    setLoading(true);
    setShowSucessModal((prev) => {
      return { ...prev, show: false };
    });
    //Call api
    editEntityData(`${USERS_API}sendInvite`, userData)
      .then((res) => {
        setShowSucessModal({
          show: true,
          type: 'success',
          title: `${t('success_messages.invitation_sent')}`,
          message: '',
        });
        setLoading(false);
        //Close modal
        handleSendInviteModal();
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response &&
          err.response.status &&
          err.response.status === 409
        ) {
          //Conflict
          setShowSucessModal({
            show: true,
            type: 'error',
            title: `${t('error_messages.error_invitation_exists')}`,
            message: '',
          });
        } else {
          setShowSucessModal({
            show: true,
            type: 'error',
            title: `${t('error_messages.error_invitation_sending')}`,
            message: '',
          });
        }
        setLoading(false);
        //Close modal
        handleSendInviteModal();
      });
  };

  /***
   * Re-send invitation to user and change its state
   */
  const reSendInvite = () => {
    setLoading(true);
    setShowSucessModal((prev) => {
      return { ...prev, show: false };
    });
    //Call api
    editEntityData(`${USERS_API}resend/invitation`, userData)
      .then((res) => {
        setShowSucessModal({
          show: true,
          type: 'success',
          title: `${t('success_messages.invitation_sent')}`,
          message: '',
        });
        setLoading(false);
        //Close modal
        handleReSendInviteModal();
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response &&
          err.response.status &&
          err.response.status === 409
        ) {
          //Conflict
          setShowSucessModal({
            show: true,
            type: 'error',
            title: `${t('error_messages.error_invitation_exists')}`,
            message: '',
          });
        } else {
          setShowSucessModal({
            show: true,
            type: 'error',
            title: `${t('error_messages.error_invitation_sending')}`,
            message: '',
          });
        }
        setLoading(false);
        //Close modal
        handleReSendInviteModal();
      });
  };

  /***
   * To show/close modal to assigned org
   */
  const handleAssignedOrgModal = () => {
    setOpenAssignedOrgForm((prev) => {
      return !prev;
    });
  };

  /***
   * To show/close modal to edit assigned org
   */
  const handleEditAssignedOrgModal = () => {
    setOpenEditAssignOrgForm((prev) => {
      return !prev;
    });
  };

  /***
   * Show/close modal to add note
   */
  const handleAddNoteModal = () => {
    setOpenAddNoteForm(!openAddNoteForm);
  };

  /***
   *
   */
  const getColumns = useMemo(
    () => [
      {
        dataField: 'organization_id.name',
        text: `${t('table_columns.organization')}`,
        sort: false,
        search: true,
        headerStyle: { width: '20%' },
        style: { alignItems: 'start' },
        formatter: (cellContent, row) => {
          return (
            <div style={{ display: 'flex' }}>
              <AppOrgIcon
                style={{ color: 'rgba(0, 0, 0, 0.6)' }}
                color={row.organization_id.color}
                height={'30px'}
                width={'30px'}
                fontSize={'12px'}
              >
                {row.organization_id.name.substring(0, 2).toUpperCase()}
              </AppOrgIcon>
              <StyledLabel style={{ marginLeft: '10px', display: 'flex', alignItems: 'center' }}>
                {row.organization_id.name}
              </StyledLabel>
            </div>
          );
        },
      },
      {
        dataField: 'organization_user_sites.site.name',
        text: `${t('table_columns.sites')}`,
        sort: false,
        search: false,
        headerStyle: { width: '20%' },
        style: { height: '54px', padding: '0px' },
        formatter: (cellContent, row) => {
          return row.organization_user_sites.map((orgSite, i) => (
            <div style={{ borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset' }}>
              <StyledNestedCell
                style={{
                  backgroundColor: row.organization_id.color,
                  display: 'block',
                  paddingTop: '13px',
                }}
              >
                {orgSite.site.name}
              </StyledNestedCell>
            </div>
          ));
        },
      },
      {
        dataField: 'organization_user_sites.site.address',
        text: `${t('table_columns.address')}`,
        sort: false,
        search: false,
        headerStyle: { width: '40%' },
        style: { height: '54px', padding: '0px' },
        formatter: (cellContent, row) => {
          return row.organization_user_sites.map((orgSite, i) => (
            <div style={{ borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset' }}>
              <StyledNestedCell style={{ display: 'block', paddingTop: '13px' }}>
                {orgSite.site.address}
              </StyledNestedCell>
            </div>
          ));
        },
      },
      {
        dataField: 'edit',
        text: `${t('table_columns.edit')}`,
        isDummyField: true,
        headerStyle: { width: '6%' },
        style: {
          height: '54px',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        formatter: (cellContent, row) => {
          //Decides if show or not site menu. Depends on system user permissions
          let showEditEmployeeButton = false;
          //Get group for each site
          const siteGroups = row?.organization_user_sites?.map(
            (ous) => ous?.site?.groupsSite[0]?.group?.id,
          );

          if (userPermissions?.type === 'system') {
            if (siteGroups.length > 0) {
              //Ensure system user group is same that site group and syste user group has full access
              if (
                userPermissions?.systemUserGroups.some(
                  (sysG) => siteGroups.includes(sysG.id) && sysG.permission === '020',
                )
              ) {
                showEditEmployeeButton = true;
              }
            } else {
              //Site without group
              showEditEmployeeButton = false;
            }
          } else {
            //Is not a system user. Can see menu
            showEditEmployeeButton = true;
          }

          if (showEditEmployeeButton) {
            return (
              <StyledLabel
                style={{
                  marginLeft: '0px',
                  justifyContent: 'center',
                  alignitems: 'center',
                }}
                onClick={() => {
                  handleEditAssignedOrgModal();
                  setOrgToEdit(row);
                }}
              >
                <Edit src={logo_edit} alt="logo"></Edit>
              </StyledLabel>
            );
          } else {
            return <></>;
          }
        },
      },
    ],
    [userPermissions, t],
  );

  return (
    <Container fluid>
      {/* Header section */}
      <EncView>
        <StyleDivTitle>{calcAvatar(userData, 60, 60)}</StyleDivTitle>
        <SpaceEncName>
          <div>
            <h3 style={{ margin: '0px' }}>{userData?.name}</h3>
            <p style={{ color: PALETTE_COLOR.LIGHT_GREY }}>
              {t('Users.employees.view.header_info')}
            </p>
          </div>
        </SpaceEncName>
        <SpaceEnc>
          {userData?.status?.value_definition === '010' && (
            <>
              <StateTag
                style={{ marginLeft: '5px' }}
                width="auto"
                icon={status_active}
                background={PALETTE_COLOR.ACTIVE_STATUS_BACKGROUND}
                textColor={PALETTE_COLOR.ACCENT_GREEN}
              >
                {t(`Profile.status.${userData?.status.description}_account`)}
              </StateTag>
            </>
          )}
          {userData?.status?.value_definition === '020' && (
            <>
              <StateTag
                style={{ marginLeft: '5px' }}
                width="auto"
                icon={status_draft}
                background={PALETTE_COLOR.DRAFT_STATUS_BACKGROUND}
                textColor={PALETTE_COLOR.INACTIVE_GRAY}
              >
                {t(`Users.status.${userData?.status.description}`)}
              </StateTag>
              {isSiteManager && (
                <SendInviteBtn width={'auto'} action={handleSendInviteModal}>
                  {t('buttons.btn_send_invite')}
                </SendInviteBtn>
              )}
            </>
          )}
          {userData?.status?.value_definition === '030' && (
            <StateTag
              style={{ marginLeft: '5px' }}
              width="auto"
              icon={status_invited}
              background={PALETTE_COLOR.INVITED_STATUS_BACKGROUND}
              textColor={PALETTE_COLOR.ACCENT_PURPLE}
            >
              {t(`Users.status.${userData?.status.description}`)}
            </StateTag>
          )}
          {userData?.status?.value_definition === '050' && (
            <>
              <StateTag
                style={{ marginLeft: '5px' }}
                width="auto"
                icon={status_blocked}
                background={PALETTE_COLOR.BLOCKED_STATUS_BACKGROUND}
                textColor={PALETTE_COLOR.TEXT_ORANGE}
              >
                {t(`Users.status.${userData?.status.description}`)}
              </StateTag>
              {isSiteManager && (
                <HeaderBtn width={'auto'} icon={send_message}>
                  {t('Users.send_message')}
                </HeaderBtn>
              )}
            </>
          )}
          {userData?.status?.value_definition === '060' && (
            <StateTag
              width="auto"
              icon={Archived}
              background={PALETTE_COLOR.ARCHIVED_STATUS_BACKGROUND}
              textColor={PALETTE_COLOR.ACCENT_RED}
            >
              {t(`Profile.status.${userData?.status.description}_account`)}
            </StateTag>
          )}
          {userData?.status?.value_definition === '060' && (
            <SpacButtonRestore
              aria-label="edit"
              sx={{ padding: '17px 10px' }}
              style={{
                background: '#FFFFFF',
                border: '1px solid #E7ECF4',
                boxSizing: 'border-box',
                borderRadius: '20px',
                height: '40px',
                marginLeft: '2%',
              }}
              onClick={() => handleClickOpenRestoreUserModal()}
            >
              <Icon src={restore} />
              <div
                style={{
                  fontWeight: '600',
                  fontSize: '13px',
                  color: '#1179BF',
                  padding: 'inherit',
                }}
              >
                {t('buttons.btn_restore_account')}
              </div>
            </SpacButtonRestore>
          )}
        </SpaceEnc>
        {userData?.status?.value_definition !== '060' && enableButton && isSiteManager ? (
          <Menu
            align="end"
            direction="bottom"
            menuButton={
              <DropDownWrapper style={{ marginLeft: '5px' }}>
                <IconButton aria-label="edit" sx={{}}>
                  <Icon src={Button_Outline} />
                </IconButton>
              </DropDownWrapper>
            }
          >
            {userData?.status?.value_definition === '030' && (
              <MenuItem onClick={handleReSendInviteModal}>{t('Users.menu.resent_invite')}</MenuItem>
            )}
            {userData?.status?.value_definition === '050' && (
              <MenuItem onClick={() => handleClickOpenChangeStatusSite(newStatusActive)}>
                {t('Users.menu.unblock_account')}
              </MenuItem>
            )}
            {userData?.status?.value_definition === '010' && (
              <MenuItem onClick={() => handleClickOpenChangeStatusSite(newStatusBlocked)}>
                {t('Users.menu.block_account')}
              </MenuItem>
            )}
            {(userData?.status?.value_definition === '050' ||
              userData?.status?.value_definition === '010') && (
              <MenuItem onClick={() => handleClickOpenChangeStatusSite(newStatusArchive)}>
                {t('Users.menu.archive_account')}
              </MenuItem>
            )}
          </Menu>
        ) : (
          <div></div>
        )}
      </EncView>
      {/* Restore employee user */}
      <AppModal
        open={openRestoreUser}
        handleclose={handleCloseRestoreUser}
        title={t('Users.employees.modal_edit.restore_title')}
        inside={1}
      >
        <RestoreEmplyUserForm
          handleclose={handleCloseRestoreUser}
          setShowSucessModal={setShowSucessModal}
          id={userData?.id}
          jobTittles={userData?.jobs_titles}
        />
      </AppModal>
      {/* Change user status */}
      <AppModal
        width={500}
        styleModal={{ padding: '30px 40px 60px 40px' }}
        open={openChangeStatusUser}
        handleclose={handleCloseChangeStatusUser}
        title={''}
        boxshadow={'none'}
        inside={1}
      >
        <AppModalStatus
          width={'500px'}
          mainPadding={'0px 0px 0px 0px'}
          actionIcon={userStatusModalContent.actionIcon}
          title={userStatusModalContent.title}
          subtitle={userStatusModalContent.subtitle}
          bgcolor={userStatusModalContent.bgcolor}
          bordercolor={userStatusModalContent.bordercolor}
          borderhovercolor={userStatusModalContent.borderhovercolor}
          bghovercolor={userStatusModalContent.bghovercolor}
          boxShadow={userStatusModalContent.boxShadow}
          buttonText={userStatusModalContent.buttonText}
          subject={userData?.email}
          buttonAction={changeStatusUser}
          isLoading={loading}
        />
      </AppModal>
      {/* Personal Info panel */}
      <Col xs={12} xl={12}>
        {isSiteManager ? (
          <AppPanel
            notitlepadding={`true`}
            title={t('Users.view.personal_title')}
            icon={userData?.status?.value_definition !== '060' && enableButton && logo_edit}
            width="100%"
            padding_card="15px"
            call={handleClickOpenSystemFormModal}
          >
            <StyleRow>
              <StyleTittle>
                <Col>{t('form_labels.name')}</Col>
                <Col>{t('form_labels.email')}</Col>
                <Col>{t('form_labels.phone')}</Col>
                <Col>{t('form_labels.pay_rate')}</Col>
              </StyleTittle>
              <StyleFields>
                <Col>{userData?.name}</Col>
                <Col>{userData?.email}</Col>
                <Col>{userData?.phone}</Col>
                <Col>${userData?.pay_rate}</Col>
              </StyleFields>
            </StyleRow>
            <StyleRow>
              <StyleTittle>
                <Col>{t('form_labels.address')}</Col>
                <Col>{t('form_labels.job_title')}</Col>
                <Col>{t('form_labels.groups')}</Col>
                <Col>{t('form_labels.employee_id')}</Col>
              </StyleTittle>
              <StyleFields>
                <Col>{userData?.home_address}</Col>
                <Col>{userData?.jobs_titles[0]?.job_title?.description}</Col>
                <Col>
                  {userData?.user_groups.map(
                    (item, index) =>
                      item?.group?.name + (index !== userData?.user_groups.length - 1 ? ', ' : ''),
                  )}
                </Col>
                <Col>{userData?.employee_id}</Col>
              </StyleFields>
            </StyleRow>
            <StyleRow>
              <StyleTittle>
                <Col>{t('form_labels.secondary_phone')}</Col>
                <Col>{t('form_labels.latitude')}</Col>
                <Col>{t('form_labels.longitude')}</Col>
                <Col>{t('form_labels.main_area')}</Col>
              </StyleTittle>
              <StyleFields>
                <Col>{userData?.secondary_phone || t('no_view_data')}</Col>
                <Col>{userData?.latitude || t('no_view_data')}</Col>
                <Col>{userData?.longitude || t('no_view_data')}</Col>
                <Col>
                  {userData?.main_area?.description
                    ? t(`Users.employees.main_areas.${userData?.main_area?.description}`)
                    : t('no_view_data')}
                </Col>
              </StyleFields>
            </StyleRow>
            <StyleRow>
              <StyleTittle>
                <Col>{t('form_labels.specific_zone')}</Col>
                <Col>{t('form_labels.gender')}</Col>
                <Col>{t('form_labels.coverage_specialty')}</Col>
                <Col>{t('form_labels.preferred_shift')}</Col>
              </StyleTittle>
              <StyleFields>
                <Col>{userData?.specific_zone?.description || t('no_view_data')}</Col>
                <Col>
                  {userData?.gender?.description
                    ? t(`Users.employees.genres.${userData?.gender?.description}`)
                    : t('no_view_data')}
                </Col>
                <Col>
                  {userData?.coverage_specialty?.description
                    ? t(
                        `Users.employees.coverage_specialties.${userData?.coverage_specialty?.description}`,
                      )
                    : t('no_view_data')}
                </Col>
                <Col>
                  {userData?.preferred_shift?.description
                    ? t(
                        `Users.employees.preferred_shifts.${userData?.preferred_shift?.description}`,
                      )
                    : t('no_view_data')}
                </Col>
              </StyleFields>
            </StyleRow>
            <StyleRow>
              <StyleTittle>
                <Col>{t('form_labels.backup_type')}</Col>
                <Col>{t('form_labels.retirement_date')}</Col>
                <Col>{t('form_labels.joining_date')}</Col>
                <Col></Col>
              </StyleTittle>
              <StyleFields>
                <Col>{userData?.backup_type?.description || t('no_view_data')}</Col>
                <Col>{userData?.retirement_date || t('no_view_data')}</Col>
                <Col>{userData?.joining_date || t('no_view_data')}</Col>
                <Col></Col>
              </StyleFields>
            </StyleRow>
          </AppPanel>
        ) : (
          <AppPanel
            notitlepadding={`true`}
            title={t('Users.view.personal_title')}
            icon={enableButton}
            width="100%"
            padding_card="15px"
            call={handleClickOpenSystemFormModal}
          >
            <StyleRow>
              <StyleTittle>
                <Col>{t('form_labels.name')}</Col>
                <Col>{t('form_labels.employee_id')}</Col>
                <Col>{t('form_labels.job_title')}</Col>
                <Col>{t('form_labels.groups')}</Col>
              </StyleTittle>
              <StyleFields>
                <Col>{userData?.name}</Col>
                <Col>{userData?.employee_id}</Col>
                <Col>{userData?.jobs_titles[0]?.job_title?.description}</Col>
                <Col>
                  {userData?.user_groups.map(
                    (item, index) =>
                      item?.group?.name + (index !== userData?.user_groups.length - 1 ? ', ' : ''),
                  )}
                </Col>
              </StyleFields>
            </StyleRow>
          </AppPanel>
        )}
        <AppModal
          open={openEditUserForm}
          handleclose={handleCloseSystemFormModal}
          title={t('Users.employees.modal_edit.title')}
          inside={1}
        >
          <EditEmplyUserForm
            handleclose={handleCloseSystemFormModal}
            setShowSucessModal={setShowSucessModal}
            id={userData?.id}
            jobTittles={userData?.jobs_titles}
            setReload={setReload}
          />
        </AppModal>
      </Col>

      <Row>
        {/* Usage Info panel */}
        {isSiteManager && (
          <Col sm={3}>
            <AppPanel
              notitlepadding={`true`}
              title={t('Users.view.usage_info_title')}
              width="100%"
              padding_card="15px"
            >
              <Card style={{ borderRight: 'unset', borderLeft: 'unset', borderTop: 'unset' }}>
                <ListGroup variant="flush" style={{ borderTop: 'unset' }}>
                  <ListGroup.Item style={{ height: '3%' }}>
                    <StyleTittle>
                      <Col>{t('Users.usage_info.invited')}</Col>
                      <StyleColContent>
                        {userData?.date_invited ? formatDate(userData.date_invited) : '-'}
                      </StyleColContent>
                    </StyleTittle>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <StyleTittle>
                      <Col>{t('Users.usage_info.registered')}</Col>
                      <StyleColContent>
                        {userData?.date_registered ? formatDate(userData.date_registered) : '-'}
                      </StyleColContent>
                    </StyleTittle>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <StyleTittle>
                      <Col>{t('Users.usage_info.last_modified')}</Col>
                      <StyleColContent>
                        {userData?.updated_at ? formatFullDate(userData.updated_at) : '-'}
                      </StyleColContent>
                    </StyleTittle>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <StyleTittle>
                      <Col>{t('Users.usage_info.last_login')}</Col>
                      <StyleColContent>
                        {userData?.last_login ? formatFullDate(userData.last_login) : '-'}
                      </StyleColContent>
                    </StyleTittle>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <StyleTittle>
                      <Col>{t('Users.usage_info.user_id')}</Col>
                      <StyleColContent>{userData?.id}</StyleColContent>
                    </StyleTittle>
                  </ListGroup.Item>
                </ListGroup>
              </Card>

              {userData?.status?.value_definition === '010' && (
                <LinkAction variant="link" className="mb-2">
                  {t('Users.usage_info.view_user_activities')}
                </LinkAction>
              )}
            </AppPanel>
          </Col>
        )}

        {/* Assigned Organization */}
        {!userData ? (
          <Col sm={9}>
            <AppPanel
              style={{
                width: '100%',
                minHeight: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Lottie
                animationData={groovyWalkAnimation}
                autoPlay={true}
                loop={true}
                style={{ height: 60 }}
              />
            </AppPanel>
          </Col>
        ) : (
          <Col sm={isSiteManager ? 9 : 12}>
            {/*isSiteManager && (
              <AppPanel
                title={t('Users.view.assigned_organization_title')}
                width="100%"
                icon_label={ic_organizations}
                header={0}
                style={
                  hasOrganizations === false
                    ? {
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }
                    : {}
                }
              >
                {hasOrganizations === false ? (
                  <>
                    <Icon
                      src={ic_big_organizations}
                      style={{ width: '47px', height: '47px', display: 'flex', marginTop: '70px' }}
                    />
                    <NoDataPanelTitle style={{ marginTop: '20px' }}>
                      {t('Users.employees.view.assignation.no_organizations_title')}
                    </NoDataPanelTitle>
                    <NoDataPanelText>
                      {t('Users.employees.view.assignation.no_organizations_subtitle')}
                    </NoDataPanelText>
                    {userData?.status?.value_definition !== '060' && enableButton && (
                      <AppButton
                        style={{
                          fontSize: 14,
                          width: 'auto',
                          marginBottom: '60px',
                          display: 'flex',
                          boxShadow: '0px 4px 10px rgba(137, 137, 255, 0.3)',
                        }}
                        tooltiptitle={t('tooltips.assign_organization')}
                        bgcolor={PALETTE_COLOR.ACCENT_PURPLE}
                        bghovercolor={PALETTE_COLOR.ACCENT_PURPLE_LIGHT}
                        bordercolor={PALETTE_COLOR.ACCENT_PURPLE}
                        borderhovercolor={PALETTE_COLOR.ACCENT_PURPLE}
                        icon={ic_add_circle}
                        onClick={() => handleAssignedOrgModal()}
                      >
                        {t('buttons.btn_assign')}
                      </AppButton>
                    )}
                  </>
                ) : (
                  hasOrganizations === true && (
                
                      <AppTable
                        endpointPath={ORGANIZATIONS_USERS_API}
                        columns={getColumns}
                        filters={filters}
                        joins={joins}
                        disablePagination={true}
                        disableTopPagination={true}
                        noBorders={true}
                        headerIcon={ic_organizations}
                        headerText={t('table_headers.organizations_header')}
                        reload={reloadTable}
                        trailingBtn={
                          enableButton && (
                            <AppButton
                              style={{
                                with: 'auto',
                                boxShadow: '0px 4px 10px rgba(137, 137, 255, 0.3)',
                              }}
                              tooltiptitle={t('tooltips.assign_organization')}
                              bgcolor={PALETTE_COLOR.ACCENT_PURPLE}
                              bghovercolor={PALETTE_COLOR.ACCENT_PURPLE_LIGHT}
                              bordercolor={PALETTE_COLOR.ACCENT_PURPLE}
                              borderhovercolor={PALETTE_COLOR.ACCENT_PURPLE}
                              icon={ic_add_circle}
                              onClick={() => handleAssignedOrgModal()}
                            >
                              {t('buttons.btn_add')}
                            </AppButton>
                          )
                        }
                      />
                  )
                )}
              </AppPanel>
            )*/}

            {isLoadingNotes ? (
              <Col sm={12}>
                <AppPanel
                  style={{
                    width: '100%',
                    minHeight: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '14px',
                  }}
                >
                  <Lottie
                    animationData={groovyWalkAnimation}
                    autoPlay={true}
                    loop={true}
                    style={{ height: 60, marginBottom: 40 }}
                  />
                </AppPanel>
              </Col>
            ) : (
              <Col sm={12}>
                <AppPanel
                  title={t('Users.view.notes_title')}
                  width="100%"
                  icon_label={ic_organizations}
                  header={0}
                  style={{
                    minHeight: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {employeeNotes.length === 0 ? (
                    <NoDataPanelWrapper>
                      <Icon
                        src={ic_note}
                        style={{ width: '36px', height: '36px', display: 'flex', margin: 'auto' }}
                      />
                      <NoDataPanelTitle style={{ marginTop: '20px' }}>
                        {t('Users.employees.view.note.no_notes_title')}
                      </NoDataPanelTitle>
                      <NoDataPanelText>
                        {t('Users.employees.view.note.no_notes_subtitle')}
                      </NoDataPanelText>
                      {userData?.status?.value_definition !== '060' && enableButton && (
                        <AppButton
                          style={{
                            fontSize: 14,
                            width: 'auto',
                            display: 'flex',
                            margin: 'auto',
                            boxShadow: '0px 4px 10px rgba(137, 137, 255, 0.3)',
                          }}
                          bgcolor={PALETTE_COLOR.ACCENT_PURPLE}
                          bghovercolor={PALETTE_COLOR.ACCENT_PURPLE_LIGHT}
                          bordercolor={PALETTE_COLOR.ACCENT_PURPLE}
                          borderhovercolor={PALETTE_COLOR.ACCENT_PURPLE}
                          icon={ic_add_circle}
                          onClick={() => handleAddNoteModal()}
                        >
                          {t('buttons.btn_add_note')}
                        </AppButton>
                      )}
                    </NoDataPanelWrapper>
                  ) : (
                    <NotesWrapper>
                      <NotesHeader>
                        <NotesHeaderColumn>
                          <NotesHeaderRow>
                            <Icon
                              src={ic_note}
                              style={{
                                width: '18px',
                                height: '18px',
                                display: 'flex',
                                marginRight: '10px',
                              }}
                            />
                            <NotesHeaderTitle>{t('Users.view.notes_title')}</NotesHeaderTitle>
                          </NotesHeaderRow>
                        </NotesHeaderColumn>
                        <NotesHeaderColumn style={{ flexDirection: 'column' }}>
                          <AppButton
                            style={{ boxShadow: '0px 4px 10px rgba(137, 137, 255, 0.3)' }}
                            tooltiptitle={t('tooltips.add_note')}
                            bgcolor={PALETTE_COLOR.ACCENT_PURPLE}
                            bghovercolor={PALETTE_COLOR.ACCENT_PURPLE_LIGHT}
                            bordercolor={PALETTE_COLOR.ACCENT_PURPLE}
                            borderhovercolor={PALETTE_COLOR.ACCENT_PURPLE}
                            icon={ic_add_circle}
                            onClick={() => handleAddNoteModal()}
                          >
                            {t('buttons.btn_add')}
                          </AppButton>
                        </NotesHeaderColumn>
                      </NotesHeader>
                      <hr />
                      {employeeNotes.map((note) => {
                        return (
                          <NoteCard
                            key={note.id}
                            data={note}
                            setReloadEmployeeNotes={setReloadEmployeeNotes}
                          />
                        );
                      })}
                    </NotesWrapper>
                  )}
                </AppPanel>
              </Col>
            )}
          </Col>
        )}
      </Row>

      {showSucessModal.show && (
        <CustomizedSnackbar
          show={showSucessModal.show}
          type={showSucessModal.type}
          title={showSucessModal.title}
          message={showSucessModal.message}
        />
      )}
      {/* <AppModal open={openEditUserForm} handleclose={handleCloseSystemFormModal} title={('Edit an Administrator')} inside={1}>
        <EditSystemUserForm handleclose={handleCloseSystemFormModal} setShowSucessModal={setShowSucessModal} data={userData} />
      </AppModal> */}

      {/* Send invite */}
      <AppModal
        width={600}
        styleModal={{ padding: '20px 5px 20px 5px' }}
        open={openSendInvite}
        handleclose={handleSendInviteModal}
        title={''}
        boxshadow={'none'}
        inside={1}
      >
        <AppModalStatus
          actionIcon={sendInvitationModalData.actionIcon}
          title={`${t('Users.modals.invite_title')} ${userData?.name}`}
          subtitle={userData?.email}
          subtitleColor={sendInvitationModalData.subtitleColor}
          subtitleWeight={sendInvitationModalData.subtitleWeight}
          bgcolor={sendInvitationModalData.bgcolor}
          bordercolor={sendInvitationModalData.bordercolor}
          borderhovercolor={sendInvitationModalData.borderhovercolor}
          bghovercolor={sendInvitationModalData.bghovercolor}
          boxShadow={sendInvitationModalData.boxShadow}
          buttonText={sendInvitationModalData.buttonText}
          subject={sendInvitationModalData.subtitle}
          subjectColor={sendInvitationModalData.subjectColor}
          subjectWeight={sendInvitationModalData.subjectWeight}
          buttonAction={sendInvite}
          isLoading={loading}
        />
      </AppModal>

      {/* Re-Send invite */}
      <AppModal
        width={600}
        styleModal={{ padding: '20px 5px 20px 5px' }}
        styles={{}}
        open={openReSendInvite}
        handleclose={handleReSendInviteModal}
        title={''}
        boxshadow={'none'}
        inside={1}
      >
        <AppModalStatus
          actionIcon={resendInvitationModalData.actionIcon}
          title={`${t('Users.modals.resend_invite_title')} ${userData?.name}`}
          subtitle={userData?.email}
          subtitleColor={resendInvitationModalData.subtitleColor}
          subtitleWeight={resendInvitationModalData.subtitleWeight}
          bgcolor={resendInvitationModalData.bgcolor}
          bordercolor={resendInvitationModalData.bordercolor}
          borderhovercolor={resendInvitationModalData.borderhovercolor}
          bghovercolor={resendInvitationModalData.bghovercolor}
          boxShadow={resendInvitationModalData.boxShadow}
          buttonText={resendInvitationModalData.buttonText}
          subject={resendInvitationModalData.subtitle}
          subjectColor={resendInvitationModalData.subjectColor}
          subjectWeight={resendInvitationModalData.subjectWeight}
          buttonAction={reSendInvite}
          isLoading={loading}
        />
      </AppModal>

      {/* Assign */}
      <AppModal
        open={openAssignedOrgForm}
        handleclose={handleAssignedOrgModal}
        title={t('Users.modals.assign_organization_title')}
        inside={1}
      >
        <AssignOrganizationForm
          handleclose={handleAssignedOrgModal}
          setShowSucessModal={setShowSucessModal}
          data={userData}
        />
      </AppModal>

      {/* Edit assign */}
      <AppModal
        open={openEditAssignOrgForm}
        handleclose={handleEditAssignedOrgModal}
        title={t('Users.modals.edit_assign_organization_title')}
        inside={1}
      >
        <EditAssignOrganizationForm
          handleclose={handleEditAssignedOrgModal}
          setShowSucessModal={setShowSucessModal}
          setReload={setReload}
          data={userData}
          org={orgToEdit}
        />
      </AppModal>

      {/* Add note */}
      <AppModal
        open={openAddNoteForm}
        handleclose={handleAddNoteModal}
        title={t('Users.employees.view.note.modal_add.title')}
        inside={1}
      >
        <NoteForm
          handleclose={handleAddNoteModal}
          setReloadEmployeeNotes={setReloadEmployeeNotes}
          data={userData}
        />
      </AppModal>
    </Container>
  );
};
