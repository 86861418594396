import { getCurrentSession } from 'services';
import appConfig from '@config/appConfig';
import { EventSource } from 'eventsource';

const baseURL = appConfig.api.ENDPOINT.replace(/\/$/, '');

export const createEventSource = async (endpoint, queryParams = '') => {
  const info = await getCurrentSession();
  const cleanEndpoint = endpoint.replace(/^\//, '');
  const url = `${baseURL}/${cleanEndpoint}${queryParams ? `?${queryParams}` : ''}`;

  return new EventSource(url, {
    fetch: (input, init) =>
      fetch(input, {
        ...init,
        headers: {
          ...init.headers,
          Authorization: `Bearer ${info.idToken.jwtToken}`,
        },
      }),
  });
};
