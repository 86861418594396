import React, { useEffect } from 'react';
import styled from 'styled-components';
import { editEntityData, getEntityData, saveEntityData } from 'services';
import { RequestQueryBuilder, CondOperator } from '@nestjsx/crud-request';
import { useSelector } from 'react-redux';
import { CHAT_MESSAGES_API } from 'services/CONSTANTS';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import { AppAvatar, AppComponentLoading } from '@components';
import moment from 'moment';
import TextField from '@mui/material/TextField';
import ic_send_message from '@assets/icons/ic_send_message.svg';
import ic_attach from '@assets/icons/ic_attach.svg';
import { useDispatch } from 'react-redux';
import { setNewChatMessageAction, setLoadingChatAction } from '@redux/actions/appActions';
import Avatar from '@assets/icons/ic_no_avatar.svg';

import { useTranslation } from 'react-i18next';
import { createEventSource } from 'utils/customEventSource';
import { setCountChatMessagesAction } from 'redux/actions/appActions';

moment.updateLocale('en', {
  calendar: {
    lastDay: '[Yesterday]',
    sameDay: '[Today]',
    nextDay: 'MMMM D, YYYY',
    lastWeek: 'MMMM D, YYYY',
    nextWeek: 'MMMM D, YYYY',
    sameElse: 'MMMM D, YYYY',
  },
});

const MainWrapper = styled.div`
  background: #ffffff;
  height: 88%;
  position: absolute;

  &::-webkit-scrollbar {
    height: 8px;
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    border-radius: 1rem;
  }

  &::-webkit-scrollbar-thumb {
    background: ${PALETTE_COLOR.FIELD_BORDER_COLOR};
    border-radius: 1rem;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${PALETTE_COLOR.FIELD_BORDER_COLOR};
  }
`;

const Message = styled.div`
  flex-direction: column;
  display: flex;
  flex: 10;
  background: ${(props) => (props.source ? '#F6F8FB' : '#FFFFFF')};
  box-shadow: 1px 1px 1px #edf1f8;
  border-radius: 16px;
  border: 1px solid #edf1f8;
  padding: 15px;
  margin-bottom: 14px;
  word-break: break-all;
`;

const AvatarWrapper = styled.div`
  flex-direction: column;
  display: flex;
  flex: 1;
`;

const MessageTitleHour = styled.div`
  flex-direction: row;
  flex: 1;
  display: flex;
  justify-content: space-between;
`;

const MessageName = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  margin-right: 4px;
`;

const MessageJobTitle = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #888888;
`;

const MessageHour = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #888888;
`;

const MessageBodyContainer = styled.div`
  flex-direction: row;
  flex: 1;
  display: flex;
  justify-content: flex-start;
`;

const MessageBody = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
`;

const StyledInputTextField = styled(TextField)`
  background: rgba(214, 223, 244, 0.9) !important;
  box-shadow: inset 0px 8px 16px rgba(0, 0, 0, 0.06) !important;
  border-radius: 32px;
  border: 0px solid transparent !important;

  &::-webkit-scrollbar {
    height: 8px !important;
    width: 4px !important;
  }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0) !important;
    border-radius: 1rem !important;
  }

  &::-webkit-scrollbar-thumb {
    background: ${PALETTE_COLOR.FIELD_BORDER_COLOR} !important;
    border-radius: 1rem !important;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${PALETTE_COLOR.FIELD_BORDER_COLOR} !important;
  }

  position: fixed !important;
  bottom: 1.5em;
  color: #fff;
  border-radius: 50px;
  width: 45%;
`;

const ArrachImg = styled.img`
  margin-right: 10px;
  cursor: pointer;
`;

const SendImg = styled.img`
  margin-left: 10px;
  height: 35px;
  width: 35px;
  cursor: pointer;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const DayLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: center;
  margin-bottom: 10px;
`;

const DayWrapper = styled.div`
  background: #f5f9ff;
  border-radius: 8px;
  padding: 4px 16px 4px 16px;
  font-weight: 300;
  font-size: 12px;
  text-align: center;
  color: #000000;
`;

const Icon = styled.img``;

export const ChatCenter = ({ destinationUser }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);
  const [chats, setChats] = React.useState([]);
  const [groupedChats, setGroupedChats] = React.useState([]);
  const [message, setMessage] = React.useState('');
  const {
    userData,
    countChatMessages,
    newChatMessage,
    loadNewChatMessageFocus,
    destinationChatUserId,
  } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const goToLastMessage = React.useRef(null);

  /***
   * Get all chats between both users
   */
  useEffect(() => {
    let es;
    const setUpStream = async () => {
      const qb = RequestQueryBuilder.create()
        .search({
          $or: [
            { $and: [{ 'sourceM.id': userData?.id }, { 'destinationM.id': destinationUser?.id }] },
            { $and: [{ 'sourceM.id': destinationUser?.id }, { 'destinationM.id': userData?.id }] },
          ],
        })
        .sortBy({ field: 'created_at', order: 'DESC' })
        .setLimit(100);
      es = await createEventSource(`${CHAT_MESSAGES_API}sse`, qb.query());
      es.onmessage = (e) => {
        const data = JSON.parse(e.data);
        if (data && data?.source?.id === userData?.id) return;
        if (data) {
          setChats((pre) => [data, ...pre]);
          setTimeout(() => {
            goToLastMessage.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
          }, 100);
        }
      };
    };

    if (userData && destinationUser) {
      setIsLoading(true);
      dispatch(setLoadingChatAction(true));
      const qb = RequestQueryBuilder.create()
        .setJoin([
          { field: 'source' },
          { field: 'destination' },
          { field: 'source.role' },
          { field: 'destination.role' },
          { field: 'source.jobs_titles' },
          { field: 'destination.jobs_titles' },
          { field: 'source.jobs_titles.job_title' },
          { field: 'destination.jobs_titles.job_title' },
        ])
        .search({
          $or: [
            { $and: [{ 'sourceM.id': userData.id }, { 'destinationM.id': destinationUser.id }] },
            { $and: [{ 'sourceM.id': destinationUser.id }, { 'destinationM.id': userData.id }] },
          ],
        })
        .sortBy({ field: 'created_at', order: 'DESC' })
        .setLimit(100);

      getEntityData(`${CHAT_MESSAGES_API}`, qb.query())
        .then((res) => {
          //Prevent when window is focus and select a different chat, to show chats in wrong chat box
          if (destinationChatUserId === destinationUser.id) {
            setChats(res);
            setIsLoading(false);
            dispatch(setLoadingChatAction(false));
            setTimeout(() => {
              goToLastMessage.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
            }, 200);
          }
        })
        .catch((err) => {
          console.log(`${JSON.stringify(err)}`);
          setIsLoading(false);
          dispatch(setLoadingChatAction(false));
        });
      setUpStream();
    }
    return () => es?.close();
  }, [destinationUser, userData, destinationChatUserId, dispatch]);

  /**
   * Listen to redux and in any change re-load message if
   * new message received is from same source and destination
   */
  useEffect(() => {
    if (
      newChatMessage &&
      newChatMessage.source &&
      newChatMessage.destination &&
      userData &&
      destinationUser &&
      !loadNewChatMessageFocus
    ) {
      if (
        parseInt(newChatMessage.source) === destinationUser.id &&
        parseInt(newChatMessage.destination) === userData.id
      ) {
        const qb = RequestQueryBuilder.create()
          .setJoin([
            { field: 'source' },
            { field: 'destination' },
            { field: 'source.role' },
            { field: 'destination.role' },
            { field: 'source.jobs_titles' },
            { field: 'destination.jobs_titles' },
            { field: 'source.jobs_titles.job_title' },
            { field: 'destination.jobs_titles.job_title' },
          ])
          .setFilter({
            field: 'id',
            operator: CondOperator.EQUALS,
            value: newChatMessage.messageId,
          });
        //Call API
        getEntityData(`${CHAT_MESSAGES_API}`, qb.query())
          .then((res) => {
            //Prevent when window is focus and select a different chat, to show chats in wrong chat box
            if (destinationChatUserId === destinationUser.id) {
              setChats((chat) => [...chat, res[0]]);
              setTimeout(() => {
                goToLastMessage.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
              }, 200);
            }
          })
          .catch((err) => {
            console.log(`${JSON.stringify(err)}`);
          });
        //Clean obj
        dispatch(setNewChatMessageAction(null));
      }
    }
  }, [
    newChatMessage,
    destinationUser,
    userData,
    destinationChatUserId,
    loadNewChatMessageFocus,
    dispatch,
  ]);

  /**
   * Background: Listen to redux and when user has window in focus and if
   * new message received is from same source and destination
   */
  useEffect(() => {
    if (userData && destinationUser && loadNewChatMessageFocus) {
      const qb = RequestQueryBuilder.create()
        .setJoin([
          { field: 'source' },
          { field: 'destination' },
          { field: 'source.role' },
          { field: 'destination.role' },
          { field: 'source.jobs_titles' },
          { field: 'destination.jobs_titles' },
          { field: 'source.jobs_titles.job_title' },
          { field: 'destination.jobs_titles.job_title' },
        ])
        .search({
          $or: [
            { $and: [{ 'sourceM.id': userData.id }, { 'destinationM.id': destinationUser.id }] },
            { $and: [{ 'sourceM.id': destinationUser.id }, { 'destinationM.id': userData.id }] },
          ],
        })
        .sortBy({ field: 'created_at', order: 'DESC' })
        .setLimit(100);
      getEntityData(`${CHAT_MESSAGES_API}`, qb.query())
        .then((res) => {
          if (destinationChatUserId === destinationUser.id) {
            //Prevent when window is focus and select a different chat, to show chats in wrong chat box
            setChats(res);
            setTimeout(() => {
              goToLastMessage.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
            }, 200);
          }
        })
        .catch((err) => {
          console.log(`${JSON.stringify(err)}`);
        });
    }
  }, [loadNewChatMessageFocus, destinationUser, userData, destinationChatUserId]);

  /***
   * Transform data to group it by date
   */
  useEffect(() => {
    if (chats && chats.length > 0) {
      //Add new property date type Date
      const localChats = chats.map((obj) => {
        return { ...obj, date: new Date(obj.created_at) };
      });
      //Sort messages
      const sortedAsc = localChats.sort((objA, objB) => objA.date.getTime() - objB.date.getTime());
      // this gives an object with dates as keys
      const chatsG = sortedAsc.reduce((chatsReduce, chatReduce) => {
        const dateMoment = moment(chatReduce.created_at).format();
        const date = dateMoment.split('T')[0];
        if (!chatsReduce[date]) {
          chatsReduce[date] = [];
        }
        chatsReduce[date].push(chatReduce);
        return chatsReduce;
      }, {});
      // Edit: to add it in the array format instead
      const groupArrays = Object.keys(chatsG).map((date) => {
        return {
          date,
          chats: chatsG[date],
        };
      });
      setGroupedChats(groupArrays);
    } else {
      setGroupedChats([]);
    }
  }, [chats]);

  /***
   * Store message
   */
  const handleChange = (event) => {
    setMessage(event.target.value);
  };

  const markMessagesAsRead = async () => {
    try {
      const usersInfo = {
        adminId: destinationUser?.id, // source_id
        employeeId: userData?.id, // destination_id
      };
      await editEntityData(`${CHAT_MESSAGES_API}markAsRead`, { usersInfo });
      dispatch(
        setCountChatMessagesAction(countChatMessages - Number(destinationUser.unreadMessages)),
      );
    } catch (error) {
      console.error(error);
    }
  };

  /***
   * In charge of send the current message and show it on
   * chat window
   */
  const sendMessage = () => {
    const currentMessage = message;
    const requestSendMessage = {
      body: currentMessage,
      source: userData,
      destination: destinationUser,
    };
    setMessage('');
    saveEntityData(CHAT_MESSAGES_API, requestSendMessage)
      .then((res) => {
        let messageSent = { ...res, source: userData, destination: destinationUser };
        setChats((c) => [...c, messageSent]);
        setTimeout(() => {
          goToLastMessage.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
        }, 200);
      })
      .catch((err) => {
        console.log('sendMessage error = ', err);
      })
      .finally(() => {
        if (destinationUser.unreadMessages > 0) markMessagesAsRead();
      });
  };

  return (
    <>
      <MainWrapper style={{ overflowY: 'scroll', width: '74%' }}>
        {isLoading ? (
          <LoadingContainer>
            <AppComponentLoading width={'100px'} height={'50px'} />
          </LoadingContainer>
        ) : (
          <div style={{ padding: '20px 200px 0px 200px', marginBottom: '50px' }}>
            {groupedChats.map((item, index) => (
              <div style={{ display: 'flex', flexDirection: 'column' }} key={index}>
                <DayLabelWrapper>
                  <DayWrapper>{moment(item.date).calendar()}</DayWrapper>
                </DayLabelWrapper>
                {item.chats.map((chat, index) => {
                  if (userData.id === chat.source.id) {
                    return (
                      <div style={{ display: 'flex', flexDirection: 'row' }} key={index}>
                        <Message source={userData.id === chat.source.id}>
                          <MessageTitleHour>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                              <MessageName>{`${chat.source.name}  `}</MessageName>
                              <MessageJobTitle>{` • ${
                                chat.source.role
                                  ? `${t(
                                      `Users.organizations.view.header_info.${chat.source.role.description.replace(
                                        /\s/g,
                                        '_',
                                      )}`,
                                    )}`
                                  : chat.source?.jobs_titles[chat.source?.jobs_titles.length - 1]
                                      ?.job_title?.description
                              }`}</MessageJobTitle>
                            </div>
                            <MessageHour>{moment(chat.created_at).format('hh:mm a')}</MessageHour>
                          </MessageTitleHour>
                          <MessageBodyContainer>
                            <MessageBody>{chat.body}</MessageBody>
                          </MessageBodyContainer>
                        </Message>
                        <AvatarWrapper style={{ marginLeft: '10px' }}>
                          {chat.source.avatar_config ? (
                            <AppAvatar
                              avatarId={JSON.parse(chat.source.avatar_config)?.avatarId}
                              bgColor={JSON.parse(chat.source.avatar_config)?.bgColor}
                              gender={JSON.parse(chat.source.avatar_config)?.gender}
                              skinColor={JSON.parse(chat.source.avatar_config)?.skinColor}
                              width={40}
                              height={40}
                            />
                          ) : chat?.source?.photo_url ? (
                            <Icon
                              src={chat.source.photo_url}
                              style={{
                                width: 40,
                                height: 40,
                                borderRadius: 60,
                                display: 'flex',
                                margin: 'auto',
                              }}
                            />
                          ) : (
                            <Icon
                              src={Avatar}
                              style={{
                                width: 40,
                                height: 40,
                                borderRadius: 60,
                                display: 'flex',
                                margin: 'auto',
                              }}
                            />
                          )}
                        </AvatarWrapper>
                      </div>
                    );
                  } else {
                    return (
                      <div style={{ display: 'flex', flexDirection: 'row' }} key={index}>
                        <AvatarWrapper style={{ marginRight: '10px' }}>
                          {chat.source.avatar_config ? (
                            <AppAvatar
                              avatarId={JSON.parse(chat.source.avatar_config)?.avatarId}
                              bgColor={JSON.parse(chat.source.avatar_config)?.bgColor}
                              gender={JSON.parse(chat.source.avatar_config)?.gender}
                              skinColor={JSON.parse(chat.source.avatar_config)?.skinColor}
                              width={40}
                              height={40}
                            />
                          ) : chat?.source?.photo_url ? (
                            <Icon
                              src={chat.source.photo_url}
                              style={{
                                width: 40,
                                height: 40,
                                borderRadius: 60,
                                display: 'flex',
                                margin: 'auto',
                              }}
                            />
                          ) : (
                            <Icon
                              src={Avatar}
                              style={{
                                width: 40,
                                height: 40,
                                borderRadius: 60,
                                display: 'flex',
                                margin: 'auto',
                              }}
                            />
                          )}
                        </AvatarWrapper>
                        <Message source={userData.id === chat.source.id}>
                          <MessageTitleHour>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                              <MessageName>{`${chat.source.name}  `}</MessageName>
                              <MessageJobTitle>{` • ${
                                chat.source.role
                                  ? `${t(
                                      `Users.organizations.view.header_info.${chat.source.role.description.replace(
                                        /\s/g,
                                        '_',
                                      )}`,
                                    )}`
                                  : chat.source?.jobs_titles[chat.source?.jobs_titles.length - 1]
                                      ?.job_title?.description
                              }`}</MessageJobTitle>
                            </div>
                            <MessageHour>{moment(chat.created_at).format('hh:mm a')}</MessageHour>
                          </MessageTitleHour>
                          <MessageBodyContainer>
                            <MessageBody>{chat.body}</MessageBody>
                          </MessageBodyContainer>
                        </Message>
                      </div>
                    );
                  }
                })}
              </div>
            ))}
          </div>
        )}
        <div style={{}} ref={goToLastMessage}></div>
      </MainWrapper>
      {/* Input text to send a message */}
      {destinationUser && !isLoading && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <StyledInputTextField
            color={'info'}
            style={{ marginTop: '20px' }}
            placeholder={t('placeholder_your_message')}
            value={message}
            onChange={handleChange}
            multiline
            maxRows={4}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                sendMessage();
              }
            }}
            InputProps={{
              style: {
                fontFamily: 'Poppins',
                fontStyle: 'normal',
                fontWeight: '300',
                fontSize: '14px',
                lineHeight: '21px',
                color: '#000000',
                border: 'none',
              },
              sx: {
                '::-webkit-scrollbar': {
                  width: '0.4rem !important',
                },
                '::-webkit-scrollbar-track': {
                  background: 'rgba(0, 0, 0, 0) !important', // transparent
                  borderRadius: '1rem !important',
                },
                '::-webkit-scrollbar-thumb': {
                  background: PALETTE_COLOR.FIELD_BORDER_COLOR,
                  borderRadius: '1rem !important',
                  boxShadow: 'inset 0 0 0.6rem rgba(0, 0, 0, 0.2) !important',
                },
                '::-webkit-scrollbar-thumb:hover': {
                  background: PALETTE_COLOR.FIELD_BORDER_COLOR,
                },
                '.css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                  borderStyle: 'none',
                },
                '.css-igs3ac-MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                  borderStyle: 'none',
                },
                '.css-igs3ac': {
                  border: 'none',
                  borderStyle: 'none',
                },
                '&:hover': {
                  '.css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                    borderStyle: 'none',
                  },
                  '.css-igs3ac-MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                    borderStyle: 'none',
                  },
                  '.css-igs3ac': {
                    border: 'none',
                    borderStyle: 'none',
                  },
                },
              },
              startAdornment: <ArrachImg src={ic_attach}></ArrachImg>,
              endAdornment: message ? (
                <SendImg src={ic_send_message} onClick={sendMessage}></SendImg>
              ) : null,
            }}
          />
        </div>
      )}
    </>
  );
};
