import React from 'react';
import styled from 'styled-components';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import ic_arrow_right_black from '@assets/icons/ic_arrow_right_black.svg';
import ic_arrow_dowm_black from '@assets/icons/ic_arrow_dowm_black.svg';
import { editEntityData, getEntityData } from 'services';
import { CHAT_MESSAGES_API } from 'services/CONSTANTS';
import { AppAvatar, AppComponentLoading } from '@components';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import {
  setDestinationChatUserIdAction,
  setCountChatMessagesAction,
  setUpdatedChatMessagesAction,
  setFocusStatus,
} from '@redux/actions/appActions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import Avatar from '@assets/icons/ic_no_avatar.svg';

import { useTranslation } from 'react-i18next';
import { createEventSource } from 'utils/customEventSource';

const ArrowWraper = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
`;

const ArrowImg = styled.img`
  border: none;
  margin-right: 10px;
`;

const ListContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

const ListColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const NameStyled = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
`;

const JobTitleStyled = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
`;

const LoadMoreWrapper = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 20px;
  background: rgba(131, 180, 49, 0.08);
  border-radius: 10px;
  padding: 10px;
  margin: 10px 30px 10px 30px;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${PALETTE_COLOR.MAIN_BLUE};
  cursor: pointer;
`;

const UserAvatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 60px;
`;

const EllipseView = styled('div')((props) => ({
  width: '20px',
  height: '20px',
  borderRadius: '16px',
  background: `${PALETTE_COLOR.ACCENT_RED_BACKGROUND}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: '15px',
  position: 'absolute',
  right: '10px',
}));

const CountMessages = styled('span')`
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 11px;
  color: ${PALETTE_COLOR.ACCENT_RED};
`;

export const UserCollapse = ({ type, search, setTotals, setChatSelected, chatSelected }) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(true);
  const [skip, setSkip] = React.useState(0);
  const [apiData, setApiData] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [loadingMore, setLoadingMore] = React.useState(false);
  const limit = 10;
  const allDataLoaded = React.useRef(0);
  const dispatch = useDispatch();
  const {
    loadingChat,
    userData,
    countChatMessages,
    usersChatMessages,
    updatedChatMessages,
    destinationChatUserId,
    focusStatus,
  } = useSelector((state) => state.app);

  const handleClick = () => {
    setOpen(!open);
  };

  /***
   * Get all users I can chat with
   */
  React.useEffect(() => {
    setIsLoading(true);

    getEntityData(
      `${CHAT_MESSAGES_API}users`,
      `type=${type}&limit=${limit}&skip=${skip}&search=${search}`,
    )
      .then((res) => {
        if (res.data) {
          //Add selected property
          const newData = res.data.map((usu) => ({
            ...usu,
            selected: false,
          }));

          setApiData((prevData) => {
            const mergedData = [...prevData, ...newData];

            const uniqueData = mergedData.reduce((acc, user) => {
              if (!acc.some((u) => u.id === user.id)) {
                acc.push(user);
              }
              return acc;
            }, []);

            return uniqueData;
          });
          //To control if load more button should be shown
          allDataLoaded.current = allDataLoaded.current + res.data.length;
        }
        //Know type and set total
        setTotals((t) => ({
          ...t,
          totalA: type === 'Administrators' ? res.count : t.totalA,
          totalE: type === 'Employees' ? res.count : t.totalE,
          totalR: type !== 'Administrators' && type !== 'Employees' ? res.count : t.totalR,
        }));

        setTotal(res.count);
      })
      .catch((err) => {
        console.log(`${JSON.stringify(err)}`);
      })
      .finally(() => {
        setIsLoading(false);
        setLoadingMore(false);
      });
  }, [skip, search, type, setTotals]);

  const selectedFromHere = React.useMemo(
    () => chatSelected?.type === type,
    [chatSelected?.type, type],
  );

  React.useEffect(() => {
    let es;

    const init = async () => {
      es = await createEventSource(`${CHAT_MESSAGES_API}users/sse`, `type=${type}&limit=${limit}`);
      es.onmessage = (e) => {
        const res = JSON.parse(e.data);

        if (res.data) {
          setApiData((prevData) => {
            const updatedData = prevData.map((user) => {
              const updatedUser = res.data.find((u) => u.id === user.id);
              if (!updatedUser) return user;
              if (chatSelected?.id === updatedUser.id) {
                return { ...user };
              }
              return {
                ...user,
                unreadMessages: updatedUser.unreadMessages ?? user.unreadMessages ?? null,
              };
            });

            return updatedData.sort((a, b) => {
              const aHasUnread = a.unreadMessages > 0 ? 1 : 0;
              const bHasUnread = b.unreadMessages > 0 ? 1 : 0;
              return bHasUnread - aHasUnread;
            });
          });
        }
      };
    };

    init();

    return () => es?.close();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatSelected]);

  /********************** Filter ********************** */
  React.useEffect(() => {
    setSkip((s) => s - s);
    allDataLoaded.current = 0;
    //setApiData([]);
    setTotal(0);
  }, [search]);
  /********************** Filter *********************** */

  /********************** Load more ******************** */
  React.useEffect(() => {
    if (loadingMore) {
      setSkip((s) => s + 10);
    }
  }, [loadingMore]);
  /********************** Load more ********************* */

  /********************** Remove selected *************** */
  React.useEffect(() => {
    if (!selectedFromHere) {
      //Mark all as false
      setApiData((users) =>
        users.map((user) => {
          user.selected = false;
          return user;
        }),
      );
    }
  }, [selectedFromHere]);
  /********************** Remove selected *************** */

  /***
   * Mark the selected chat
   */
  const changeSelectedStatus = React.useCallback(
    async (userSelected) => {
      //Store in redux user id selected
      dispatch(setDestinationChatUserIdAction(userSelected.id));
      const tmpUserSelected = JSON.parse(JSON.stringify(userSelected));
      //mark messages as read
      //Build obj to send
      const usersInfo = {
        adminId: userSelected.id, // source_id
        employeeId: userData.id, // destination_id
      };
      editEntityData(`${CHAT_MESSAGES_API}markAsRead`, { usersInfo })
        .then((res) => {
          //Mark all as false
          const users = apiData.map((u) => {
            if (u.id === userSelected.id) {
              u.selected = true;
              u.unreadMessages = 0;
            } else {
              u.selected = false;
            }
            return u;
          });
          setApiData(users);
          userSelected.type = type;
          setChatSelected(focusStatus ? tmpUserSelected : userSelected);
          dispatch(
            setCountChatMessagesAction(countChatMessages - Number(tmpUserSelected.unreadMessages)),
          );
          dispatch(setFocusStatus(false));
        })
        .catch((err) => {
          console.log('err', err);
        });
    },
    [apiData, countChatMessages, dispatch, focusStatus, setChatSelected, type, userData],
  );

  const updateUsersListCounters = React.useCallback(() => {
    if (usersChatMessages.length) {
      const users = apiData.map((u) => {
        const updatedUser = usersChatMessages.find((el) => el.source_id === u.id);
        if (updatedUser) {
          u.unreadMessages = Number(updatedUser.unreadMessages);
          if (updatedUser.source_id === destinationChatUserId) {
            u.selected = true;
            changeSelectedStatus(u);
          }
        }
        return u;
      });
      setApiData(users);
      dispatch(setUpdatedChatMessagesAction(false));
    }
  }, [destinationChatUserId, apiData, dispatch, usersChatMessages, changeSelectedStatus]);

  React.useEffect(() => {
    if (updatedChatMessages) {
      updateUsersListCounters();
    }
  }, [updatedChatMessages, updateUsersListCounters]);

  return (
    <List
      sx={{ width: '100%', bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <ListItemButton onClick={handleClick}>
        {open ? (
          <ArrowWraper>
            <ArrowImg src={ic_arrow_dowm_black} alt="ic_arrow_dowm_black" />
            {`${t(`MessageCenter.chat.types.${type}`)} • ${total ? total : 0}`}
          </ArrowWraper>
        ) : (
          <ArrowWraper>
            <ArrowImg src={ic_arrow_right_black} alt="ic_arrow_right_black" />
            {`${t(`MessageCenter.chat.types.${type}`)} • ${total ? total : 0}`}
          </ArrowWraper>
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {isLoading && skip === 0 ? (
            <AppComponentLoading style={{ width: 'auto' }} />
          ) : (
            apiData &&
            apiData?.map((user, index) => {
              const avatarConfig = user.id
                ? user.avatar_config
                  ? JSON.parse(user.avatar_config)
                  : null
                : null;
              return (
                <div key={`div-${index}`}>
                  <ListItemButton
                    key={index}
                    sx={{
                      pl: 4,
                      '&.Mui-selected': {
                        backgroundColor: `${PALETTE_COLOR.MAIN_BLUE}`,
                        color: 'white !important',
                      },
                      '&.Mui-focusVisible': {
                        backgroundColor: `${PALETTE_COLOR.MAIN_BLUE}`,
                        color: 'white !important',
                      },
                      ':hover': {
                        backgroundColor: `${PALETTE_COLOR.MAIN_BLUE}`,
                        color: 'white !important',
                      },
                    }}
                    selected={user.selected}
                    onClick={() => changeSelectedStatus(user)}
                    disabled={loadingChat}
                  >
                    <ListContainerRow>
                      <ListColumn style={{ marginRight: '10px' }}>
                        {avatarConfig ? (
                          <AppAvatar
                            avatarId={avatarConfig?.avatarId}
                            bgColor={avatarConfig?.bgColor}
                            gender={avatarConfig?.gender}
                            skinColor={avatarConfig?.skinColor}
                            width={40}
                            height={40}
                          />
                        ) : user?.photo_url ? (
                          <UserAvatar src={user.photo_url} alt="photo" />
                        ) : (
                          <UserAvatar src={Avatar} alt="avatar" />
                        )}
                      </ListColumn>
                      <ListColumn
                        style={{
                          justifyContent: 'center',
                          alignContent: 'center',
                        }}
                      >
                        <NameStyled>{user.name}</NameStyled>
                        <JobTitleStyled style={{ color: '#6d737b' }}>
                          {user.job_title
                            ? user.job_title
                            : `${t(
                                `Users.organizations.view.header_info.${user.role.replace(
                                  /\s/g,
                                  '_',
                                )}`,
                              )}`}
                        </JobTitleStyled>
                        {user.unreadMessages > 0 && (
                          <EllipseView>
                            <CountMessages>{user.unreadMessages}</CountMessages>
                          </EllipseView>
                        )}
                      </ListColumn>
                      <div style={{ display: 'flex', flexDirection: 'column' }}></div>
                    </ListContainerRow>
                  </ListItemButton>
                </div>
              );
            })
          )}
        </List>
        <ListContainerRow
          style={{
            justifyContent: 'center',
            alignContent: 'center',
            flex: 1,
          }}
        >
          {total > allDataLoaded.current && (
            <LoadMoreWrapper onClick={() => setLoadingMore(true)}>
              {loadingMore ? (
                <AppComponentLoading width={'60px'} height={'20px'} />
              ) : (
                <div>{t('MessageCenter.chat.load_more')}</div>
              )}
            </LoadMoreWrapper>
          )}
        </ListContainerRow>
      </Collapse>
    </List>
  );
};
