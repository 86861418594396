import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import { RequestQueryBuilder, CondOperator } from '@nestjsx/crud-request';
import * as Yup from 'yup';
import styled from 'styled-components';
import { Row, Col, Form } from 'react-bootstrap';
import LabelForm from '@components/LabelForm';
import {
  AppButton,
  InputForm,
  CustomizedSnackbar,
  AppDropDownCheckBoxSelect,
  AppAsyncLocationDropdown,
  AppModal,
} from '@components';
import { editEntityData, getEntityData, obtainEntityData, saveEntityData } from '@services';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import { USERS_API, VALUES_DEFINITIONS_API, GROUPS_API } from '@services/CONSTANTS';
import { AppDropDownSelect } from '@components/AppDropDownSelect';
import dollarCircleIcon from '@assets/icons/dollar_circle.svg';
import ico_warning_triangle from '@assets/icons/ico_warning_triangle.svg';
import Lottie from 'lottie-react';
import groovyWalkAnimation from '@assets/animations/72798-skeleton.json';
import { AppModalStatus } from '@components/AppModalStatus';
import DateAdapter from '@mui/lab/AdapterMoment';
import DPICon from '@assets/icons/ic_datepicker.svg';

import { useTranslation } from 'react-i18next';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import i18next from 'i18next';
import { DatePickerInput } from './AddEmplyUserForm';

const StyleButtons = styled.div`
  padding-top: 30px;
  margin: 30px 0px 0px 0px;
  border-top: 0.2px solid #8080803d;
  height: 80px;
`;

const ErrorText = styled.p`
  font-size: 13px;
  color: ${PALETTE_COLOR.ACCENT_RED};
`;

let reqEditEmployee = {
  name: '',
  phone: '',
  email: '',
  pay_rate: '',
  home_address: '',
  type: {},
  request_jobs_titles: [],
  request_user_groups: [],
};

export const EditEmplyUserForm = ({
  handleclose,
  setShowSucessModal,
  id,
  jobTittles,
  setReload,
}) => {
  const { t } = useTranslation();
  const editEmployeeSchema = Yup.object().shape({
    name: Yup.string()
      .max(100, `${t('validation.must_be_name')}`)
      .required(`${t('validation.name_required')}`),
    phone: Yup.string().required(`${t('validation.phone_required')}`),
    email: Yup.string()
      .email(`${t('validation.email_invalid')}`)
      .required(`${t('validation.email_required')}`),
    groups: Yup.array().nullable(),
    pay_rate: Yup.string()
      .required(`${t('validation.pay_rate_required')}`)
      .matches(/^\d{1,5}(\.\d{1,2})?$/, `${t('validation.pay_rate_invalid')}`),
    home_address: Yup.object()
      .nullable()
      .required(`${t('validation.home_address_required')}`),
    employee_id: Yup.string()
      .max(10, `${t('Users.employees.modal_edit.validation.must_be_employee')}`)
      .required(`${t('validation.employee_id_required')}`),
    job_title_id: Yup.object()
      .nullable()
      .required(`${t('validation.job_title_required')}`),
    secondary_phone: Yup.string().nullable(),
    latitude: Yup.number()
      .nullable()
      .typeError(`${t('validation.latitude_invalid')}`),
    longitude: Yup.number()
      .nullable()
      .typeError(`${t('validation.longitude_invalid')}`),
    retirement_date: Yup.date()
      .nullable()
      .typeError(`${t('validation.retirement_date_invalid')}`),
    joining_date: Yup.date()
      .nullable()
      .typeError(`${t('validation.joining_date_invalid')}`),
    action: Yup.string(),
  });
  const { valuesDefinitions } = useSelector((state) => state.app);

  const jobTitlesList = valuesDefinitions.filter(
    (v) => v.definitionType.definition_type === 'JOBS_TYPES',
  );
  const mainAreasList = valuesDefinitions.filter(
    (v) => v.definitionType.definition_type === 'USER_MAIN_AREA_OPTIONS',
  );
  const specificZonesList = valuesDefinitions.filter(
    (v) => v.definitionType.definition_type === 'USER_SPECIFIC_ZONE_OPTIONS',
  );
  const gendersList = valuesDefinitions.filter(
    (v) => v.definitionType.definition_type === 'USER_GENDER_OPTIONS',
  );
  const coverageSpecialtiesList = valuesDefinitions.filter(
    (v) => v.definitionType.definition_type === 'USER_COVERAGE_SPECIALTY_OPTIONS',
  );
  const preferredShiftsList = valuesDefinitions.filter(
    (v) => v.definitionType.definition_type === 'USER_PREFERRED_SHIFT_OPTIONS',
  );
  const backupTypesList = valuesDefinitions.filter(
    (v) => v.definitionType.definition_type === 'USER_BACKUP_TYPE_OPTIONS',
  );


  const [groupsList, setGroupsList] = useState([]);
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState({
    show: false,
    type: '',
    title: '',
    message: '',
  });
  //Open/close modal
  const [openEmailConfirmation, setOpenEmailConfirmation] = useState(false);
  //Save form values
  const formValues = useRef({});
  //Redux
  const { userPermissions } = useSelector((state) => state.app);
  //Content to show in change email modal
  const changeEmailModalData = {
    buttonText: `${t('buttons.btn_confirm')}`,
    actionIcon: ico_warning_triangle,
    title: ``,
    subtitle: `${t('Profile.change_personal.changing_email_description')}`,
    subtitleWeight: 400,
    subtitleColor: PALETTE_COLOR.BLACK,
    subjectColor: '#000000',
    subjectWeight: 400,
    bgcolor: PALETTE_COLOR.ACCENT_ORANGE,
    bordercolor: PALETTE_COLOR.ACCENT_ORANGE,
    borderhovercolor: PALETTE_COLOR.ACCENT_ORANGE_LIGHT,
    bghovercolor: PALETTE_COLOR.ACCENT_ORANGE_LIGHT,
    boxShadow: '0px 4px 10px rgba(255, 124, 50, 0.3)',
  };

  /***
   * Open/close change email modal
   */
  const handleChangeEmailModal = () => {
    setOpenEmailConfirmation(!openEmailConfirmation);
  };

  /***
   *
   */
  useEffect(() => {
    setLoading(true);
    obtainEntityData(`${USERS_API}${id}`)
      .then((res) => {
        setUser(res);
        setLoading(false);
      })
      .catch((err) => {
        console.log('axios err=', err);
        setLoading(false);
      });
  }, [id]);

  /***
   *
   */
  const loadLocationOptions = (find) => {
    return new Promise((resolve, reject) => {
      try {
        saveEntityData(`${USERS_API}search/location/aws`, { term: find }).then((res) => {
          const data = res.Results.map((item) => {
            return {
              label: item.Place.Label,
              value: item.Place.Label,
              find,
              place: item.Place,
            };
          });
          resolve(data);
        });
      } catch (error) {
        console.error('in loadOptions, Err===', error);
        reject([]);
      }
    });
  };

  /***
   * Get groups to filter
   */
  useEffect(() => {
    const getGroups = () => {
      //Use user groups on filter if it is a system user
      if (userPermissions?.type === 'system') {
        if (userPermissions.systemUserGroups && userPermissions?.systemUserGroups.length > 0) {
          setGroupsList(
            userPermissions?.systemUserGroups?.filter((group) => group.permission === '020'),
          );
        } else {
          setGroupsList([]);
        }
      } else {
        const qb = RequestQueryBuilder.create()
          .sortBy({ field: 'name', order: 'ASC' })
          .select(['id', 'name']);
        getEntityData(GROUPS_API, qb.query())
          .then((res) => {
            setGroupsList(res);
          })
          .catch((err) => {
            console.log('axios err=', err);
          });
      }
    };
    getGroups();
  }, [userPermissions]);

  const employeeType = valuesDefinitions.filter(
    (v) => v.definitionType.definition_type === 'USER_TYPES' && v.value_definition === '010',
  )[0]; //Employees

  reqEditEmployee.type = employeeType;

  /***
   * Decides if open change email modal or not
   */
  const editUserChangeEmail = (values) => {
    formValues.current = values;
    if (user.email !== values.email) {
      handleChangeEmailModal();
    } else {
      editEmployee();
    }
  };

  /***
   *
   */
  const editEmployee = () => {
    setOpenEmailConfirmation(false);
    //Build request
    reqEditEmployee.name = formValues.current.name;
    reqEditEmployee.phone = formValues.current.phone;
    reqEditEmployee.email = formValues.current.email;
    reqEditEmployee.pay_rate = formValues.current.pay_rate;
    reqEditEmployee.home_address = formValues.current.home_address.value;
    reqEditEmployee.employee_id = formValues.current.employee_id;
    reqEditEmployee.secondary_phone = formValues.current.secondary_phone || null;
    reqEditEmployee.latitude = formValues.current.latitude || null;
    reqEditEmployee.longitude = formValues.current.longitude || null;
    reqEditEmployee.main_area = formValues.current.main_area?.value ?? null;
    reqEditEmployee.specific_zone = formValues.current.specific_zone?.value ?? null;
    reqEditEmployee.gender = formValues.current.gender?.value ?? null;
    reqEditEmployee.coverage_specialty = formValues.current.coverage_specialty?.value ?? null;
    reqEditEmployee.preferred_shift = formValues.current.preferred_shift?.value ?? null;
    reqEditEmployee.backup_type = formValues.current.backup_type?.value ?? null;
    reqEditEmployee.retirement_date = formValues.current.retirement_date || null;
    reqEditEmployee.joining_date = formValues.current.joining_date || null;

    setShowErrorMessage((prev) => {
      return { ...prev, show: false };
    });
    setShowSucessModal((prev) => {
      return { ...prev, show: false };
    });
    const request_jobs_titles = [formValues?.current?.job_title_id?.value];
    const request_user_groups = formValues?.current.groups.map((s) => ({
      group: s.value,
      group_permission: {
        value_definition_id: null,
      },
    }));
    reqEditEmployee.status = user.status;
    reqEditEmployee = {
      ...reqEditEmployee,
      request_jobs_titles,
      request_user_groups,
    };
    setRequestLoading(true);
    editEntityData(`${USERS_API}${id}`, reqEditEmployee)
      .then((res) => {
        //Show message on GroupList view
        setShowSucessModal({
          show: true,
          type: 'success',
          title: `${t('success_messages.user_edited')}`,
          message: '',
        });
        //Close modal
        handleclose();
        setRequestLoading(false);
        setReload(true);
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response &&
          err.response.status &&
          err.response.status === 409
        ) {
          if (err.response?.data?.message === 'employeeID') {
            setShowErrorMessage({
              show: true,
              type: 'error',
              title: `${t('error_messages.employee_id_exists')}`,
              message: '',
            });
          } else {
            setShowErrorMessage({
              show: true,
              type: 'error',
              title: `${t('error_messages.user_exists')}`,
              message: '',
            });
          }
        } else {
          setShowErrorMessage({
            show: true,
            type: 'error',
            title: `${t('error_messages.error_user_editing')}`,
            message: '',
          });
        }
        setRequestLoading(false);
      });
  };

  if (loading) {
    return (
      <div style={{ width: '590px' }}>
        <Lottie
          animationData={groovyWalkAnimation}
          autoPlay={true}
          loop={true}
          style={{ height: 60 }}
        />
      </div>
    );
  }
  return (
    <Formik
      validationSchema={editEmployeeSchema}
      onSubmit={editUserChangeEmail}
      initialValues={{
        name: user.name,
        email: user.email,
        phone: user.phone,
        pay_rate: user.pay_rate,
        home_address: { label: user.home_address, value: user.home_address },
        employee_id: user.employee_id,
        job_title_id: user.jobs_titles
          ? {
              label: user?.jobs_titles[0]?.job_title?.description,
              value: user?.jobs_titles[0]?.job_title,
            }
          : null,
        groups: user.user_groups
          ? user.user_groups.map((gs) => ({ label: gs.group.name, value: gs.group.id }))
          : [],
        secondary_phone: user.secondary_phone,
        latitude: user.latitude,
        longitude: user.longitude,
        main_area: user.main_area
          ? {
              label: t(`Users.employees.main_areas.${user.main_area.description}`),
              value: user.main_area,
            }
          : null,
        specific_zone: user.specific_zone
          ? { label: user.specific_zone.description, value: user.specific_zone }
          : null,
        gender: user.gender
          ? { label: t(`Users.employees.genres.${user.gender.description}`), value: user.gender }
          : null,
        coverage_specialty: user.coverage_specialty
          ? {
              label: t(
                `Users.employees.coverage_specialties.${user.coverage_specialty.description}`,
              ),
              value: user.coverage_specialty,
            }
          : null,
        preferred_shift: user.preferred_shift
          ? {
              label: t(`Users.employees.preferred_shifts.${user.preferred_shift.description}`),
              value: user.preferred_shift,
            }
          : null,
        backup_type: user.backup_type
          ? { label: user.backup_type.description, value: user.backup_type }
          : null,
        retirement_date: user.retirement_date ? new Date(user.retirement_date) : null,
        joining_date: user.joining_date ? new Date(user.joining_date) : null,
      }}
    >
      {({ handleSubmit, handleChange, handleBlur, values, errors, touched, setFieldValue }) => (
        <div style={{ width: '590px', fontSize: '14px' }}>
          <Form onSubmit={handleSubmit} style={{ padding: '23px 30px 7px' }}>
            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.name')}></LabelForm>
              </Col>
              <Col xs={9}>
                <InputForm
                  className="mb-0"
                  inpfontsize={'14px'}
                  height={'40px'}
                  placeholder={t('required')}
                  name="name"
                  value={values.name}
                  invalid={touched.name && errors.name}
                  maxLength={100}
                  onChange={handleChange}
                />
                {touched.name && errors.name && (
                  <ErrorText className="px-2 mb-0">{errors.name}</ErrorText>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.phone')}></LabelForm>
              </Col>
              <Col xs={9}>
                <InputForm
                  className="mb-0"
                  inpfontsize={'14px'}
                  height={'40px'}
                  placeholder={t('required')}
                  name="phone"
                  ctype={'phone'}
                  inputProps={{
                    name: 'phone',
                  }}
                  country={'us'}
                  value={values.phone}
                  invalid={touched.phone && errors.phone}
                  onChange={(value, data, event, formattedValue) =>
                    setFieldValue('phone', formattedValue)
                  }
                  maxLength="10"
                />
                {touched.phone && errors.phone && (
                  <ErrorText className="px-2 mb-0">{errors.phone}</ErrorText>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.email')}></LabelForm>
              </Col>
              <Col xs={9}>
                <InputForm
                  className="mb-0"
                  inpfontsize={'14px'}
                  height={'40px'}
                  placeholder={t('required')}
                  name="email"
                  value={values.email}
                  invalid={touched.email && errors.email}
                  onChange={handleChange}
                />
                {touched.email && errors.email && (
                  <ErrorText className="px-2 mb-0">{errors.email}</ErrorText>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.job_title')}></LabelForm>
              </Col>
              <Col xs={9}>
                <AppDropDownSelect
                  options={jobTitlesList.map((option) => ({
                    label: option.description,
                    value: option,
                  }))}
                  noOptionsMessage={() => t('no_options')}
                  isClearable={true}
                  name="job_title_id"
                  placeholder={t('required')}
                  isLoading={loading}
                  onChange={(selected) => {
                    setFieldValue('job_title_id', selected);
                  }}
                  invalid={touched.job_title_id && errors.job_title_id}
                  defaultValue={values.job_title_id}
                />
                {touched.job_title_id && errors.job_title_id && (
                  <ErrorText className="px-2 mb-0">{errors.job_title_id}</ErrorText>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.groups')}></LabelForm>
              </Col>
              <Col xs={9}>
                <AppDropDownCheckBoxSelect
                  isSearchable={true}
                  allowSelectAll={false}
                  height="7rem"
                  width={390}
                  options={groupsList.map((option) => ({ label: option.name, value: option.id }))}
                  defaultValue={values.groups}
                  onChange={(selected, event, selectedValues) => {
                    setFieldValue('groups', selectedValues);
                  }}
                  targetComponent={
                    <AppButton
                      loading={requestLoading}
                      size="lg"
                      style={{
                        fontSize: 14,
                        width: 'auto',
                        display: 'unset',
                        flexDirection: 'unset',
                      }}
                      color={PALETTE_COLOR.MAIN_BLUE}
                      bgcolor={PALETTE_COLOR.MAIN_WHITE}
                      bordercolor={PALETTE_COLOR.TOP_GRAY}
                      bghovercolor={PALETTE_COLOR.MAIN_WHITE}
                      borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                      hovercolor={PALETTE_COLOR.MAIN_BLUE}
                    >
                      {t('buttons.btn_add')}
                    </AppButton>
                  }
                />
                {touched.groups && errors.groups && (
                  <ErrorText className="px-2 mb-0">{errors.groups}</ErrorText>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.pay_rate')}></LabelForm>
              </Col>
              <Col xs={5}>
                <InputForm
                  className="mb-0"
                  inpfontsize={'14px'}
                  height={'40px'}
                  icon={dollarCircleIcon}
                  iconpaddingright="unset"
                  placeholder={t('placeholder_zero_money')}
                  name="pay_rate"
                  value={values.pay_rate}
                  invalid={touched.pay_rate && errors.pay_rate}
                  onChange={handleChange}
                  maxLength="8"
                />
                {touched.pay_rate && errors.pay_rate && (
                  <ErrorText className="px-2 mb-0">{errors.pay_rate}</ErrorText>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.address')}></LabelForm>
              </Col>
              <Col xs={9}>
                <AppAsyncLocationDropdown
                  loadOptions={(values) => loadLocationOptions(values)}
                  //onInputChange={(value) => setQuery(value)}
                  setLeftPadding
                  height="200px"
                  placeholder={t('required')}
                  isClearable={true}
                  name="home_address"
                  defaultValue={values.home_address}
                  onChange={(selected) => {
                    setFieldValue('home_address', selected);
                  }}
                />
                {touched.home_address && errors.home_address && (
                  <ErrorText className="px-2 mb-0">{errors.home_address}</ErrorText>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.employee_id')}></LabelForm>
              </Col>
              <Col xs={9}>
                <InputForm
                  inpfontsize={'14px'}
                  className="mb-0"
                  height={'40px'}
                  placeholder={t('required')}
                  name="employee_id"
                  value={values.employee_id}
                  invalid={touched.employee_id && errors.employee_id}
                  maxLength={10}
                  onChange={handleChange}
                />
                {touched.employee_id && errors.employee_id && (
                  <ErrorText className="px-2 mb-0">{errors.employee_id}</ErrorText>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.secondary_phone')}></LabelForm>
              </Col>
              <Col xs={9}>
                <InputForm
                  className="mb-0"
                  inpfontsize={'14px'}
                  height={'40px'}
                  placeholder={t('optional')}
                  name="secondary_phone"
                  ctype={'phone'}
                  inputProps={{
                    name: 'secondary_phone',
                  }}
                  country={'us'}
                  value={values.secondary_phone}
                  invalid={touched.secondary_phone && errors.secondary_phone}
                  onChange={(value, data, event, formattedValue) =>
                    setFieldValue('secondary_phone', formattedValue)
                  }
                  maxLength="10"
                />
                {touched.secondary_phone && errors.secondary_phone && (
                  <ErrorText className="px-2 mb-0">{errors.secondary_phone}</ErrorText>
                )}
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.latitude')}></LabelForm>
              </Col>
              <Col xs={9}>
                <InputForm
                  className="mb-0"
                  inpfontsize={'14px'}
                  height={'40px'}
                  placeholder={t('optional')}
                  name="latitude"
                  value={values.latitude}
                  invalid={touched.latitude && errors.latitude}
                  onChange={handleChange}
                />
                {touched.latitude && errors.latitude && (
                  <ErrorText className="px-2 mb-0">{errors.latitude}</ErrorText>
                )}
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.longitude')}></LabelForm>
              </Col>
              <Col xs={9}>
                <InputForm
                  className="mb-0"
                  inpfontsize={'14px'}
                  height={'40px'}
                  placeholder={t('optional')}
                  name="longitude"
                  value={values.longitude}
                  invalid={touched.longitude && errors.longitude}
                  onChange={handleChange}
                />
                {touched.longitude && errors.longitude && (
                  <ErrorText className="px-2 mb-0">{errors.longitude}</ErrorText>
                )}
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.main_area')}></LabelForm>
              </Col>
              <Col xs={9}>
                <AppDropDownSelect
                  options={mainAreasList.map((option) => ({
                    label: t(`Users.employees.main_areas.${option.description}`),
                    value: option,
                  }))}
                  noOptionsMessage={() => t('no_options')}
                  isClearable={true}
                  name="main_area"
                  placeholder={t('optional')}
                  isLoading={loading}
                  onChange={(selected) => {
                    setFieldValue('main_area', selected);
                  }}
                  invalid={touched.main_area && errors.main_area}
                  defaultValue={values.main_area}
                />
                {touched.main_area && errors.main_area && (
                  <ErrorText className="px-2 mb-0">{errors.main_area}</ErrorText>
                )}
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.specific_zone')}></LabelForm>
              </Col>
              <Col xs={9}>
                <AppDropDownSelect
                  options={specificZonesList.map((option) => ({
                    label: option.description,
                    value: option,
                  }))}
                  noOptionsMessage={() => t('no_options')}
                  isClearable={true}
                  name="specific_zone"
                  placeholder={t('optional')}
                  isLoading={loading}
                  onChange={(selected) => {
                    setFieldValue('specific_zone', selected);
                  }}
                  invalid={touched.specific_zone && errors.specific_zone}
                  defaultValue={values.specific_zone}
                />
                {touched.specific_zone && errors.specific_zone && (
                  <ErrorText className="px-2 mb-0">{errors.specific_zone}</ErrorText>
                )}
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.gender')}></LabelForm>
              </Col>
              <Col xs={9}>
                <AppDropDownSelect
                  options={gendersList.map((option) => ({
                    label: t(`Users.employees.genres.${option.description}`),
                    value: option,
                  }))}
                  noOptionsMessage={() => t('no_options')}
                  isClearable={true}
                  name="gender"
                  placeholder={t('optional')}
                  isLoading={loading}
                  onChange={(selected) => {
                    setFieldValue('gender', selected);
                  }}
                  invalid={touched.gender && errors.gender}
                  defaultValue={values.gender}
                />
                {touched.gender && errors.gender && (
                  <ErrorText className="px-2 mb-0">{errors.gender}</ErrorText>
                )}
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.coverage_specialty')}></LabelForm>
              </Col>
              <Col xs={9}>
                <AppDropDownSelect
                  options={coverageSpecialtiesList.map((option) => ({
                    label: t(`Users.employees.coverage_specialties.${option.description}`),
                    value: option,
                  }))}
                  noOptionsMessage={() => t('no_options')}
                  isClearable={true}
                  name="coverage_specialty"
                  placeholder={t('optional')}
                  isLoading={loading}
                  onChange={(selected) => {
                    setFieldValue('coverage_specialty', selected);
                  }}
                  invalid={touched.coverage_specialty && errors.coverage_specialty}
                  defaultValue={values.coverage_specialty}
                />
                {touched.coverage_specialty && errors.coverage_specialty && (
                  <ErrorText className="px-2 mb-0">{errors.coverage_specialty}</ErrorText>
                )}
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.preferred_shift')}></LabelForm>
              </Col>
              <Col xs={9}>
                <AppDropDownSelect
                  options={preferredShiftsList.map((option) => ({
                    label: t(`Users.employees.preferred_shifts.${option.description}`),
                    value: option,
                  }))}
                  noOptionsMessage={() => t('no_options')}
                  isClearable={true}
                  name="preferred_shift"
                  placeholder={t('optional')}
                  isLoading={loading}
                  onChange={(selected) => {
                    setFieldValue('preferred_shift', selected);
                  }}
                  invalid={touched.preferred_shift && errors.preferred_shift}
                  defaultValue={values.preferred_shift}
                />
                {touched.preferred_shift && errors.preferred_shift && (
                  <ErrorText className="px-2 mb-0">{errors.preferred_shift}</ErrorText>
                )}
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.backup_type')}></LabelForm>
              </Col>
              <Col xs={9}>
                <AppDropDownSelect
                  options={backupTypesList.map((option) => ({
                    label: option.description,
                    value: option,
                  }))}
                  noOptionsMessage={() => t('no_options')}
                  isClearable={true}
                  name="backup_type"
                  placeholder={t('optional')}
                  isLoading={loading}
                  onChange={(selected) => {
                    setFieldValue('backup_type', selected);
                  }}
                  invalid={touched.backup_type && errors.backup_type}
                  defaultValue={values.backup_type}
                />
                {touched.backup_type && errors.backup_type && (
                  <ErrorText className="px-2 mb-0">{errors.backup_type}</ErrorText>
                )}
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.retirement_date')}></LabelForm>
              </Col>
              <Col xs={9}>
                <LocalizationProvider
                  dateAdapter={DateAdapter}
                  locale={i18next.resolvedLanguage}
                  style={{ fontSize: '14px' }}
                >
                  <DatePicker
                    style={{ fontSize: '14px' }}
                    label
                    value={values.retirement_date}
                    inputFormat="LL"
                    onChange={(newValue) => {
                      setFieldValue('retirement_date', newValue);
                    }}
                    components={{
                      OpenPickerIcon: () => <img src={DPICon} alt="calendar" />,
                    }}
                    renderInput={(params) => (
                      <DatePickerInput
                        InputLabelProps={{ shrink: false }}
                        hiddenLabel
                        size="small"
                        placeholder={t('optional')}
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
                {touched.retirement_date && errors.retirement_date && (
                  <ErrorText className="px-2 mb-0">{errors.retirement_date}</ErrorText>
                )}
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={3}>
                <LabelForm tittle={t('form_labels.joining_date')}></LabelForm>
              </Col>
              <Col xs={9}>
                <LocalizationProvider
                  dateAdapter={DateAdapter}
                  locale={i18next.resolvedLanguage}
                  style={{ fontSize: '14px' }}
                >
                  <DatePicker
                    style={{ fontSize: '14px' }}
                    label
                    value={values.joining_date}
                    inputFormat="LL"
                    onChange={(newValue) => {
                      setFieldValue('joining_date', newValue);
                    }}
                    components={{
                      OpenPickerIcon: () => <img src={DPICon} alt="calendar" />,
                    }}
                    renderInput={(params) => (
                      <DatePickerInput
                        InputLabelProps={{ shrink: false }}
                        hiddenLabel
                        size="small"
                        placeholder={t('optional')}
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
                {touched.joining_date && errors.joining_date && (
                  <ErrorText className="px-2 mb-0">{errors.joining_date}</ErrorText>
                )}
              </Col>
            </Row>
            <StyleButtons>
              <span>
                <AppButton
                  loading={requestLoading}
                  style={{
                    fontSize: 14,
                    width: 'auto',
                    marginRight: 16,
                    display: 'unset',
                    flexDirection: 'unset',
                  }}
                  color={PALETTE_COLOR.MAIN_WHITE}
                  bgcolor={PALETTE_COLOR.MAIN_GREEN}
                  bordercolor={PALETTE_COLOR.MAIN_GREEN}
                  bghovercolor={PALETTE_COLOR.MAIN_GREEN}
                  borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                  size="lg"
                  type="submit"
                  title={t('buttons.btn_update')}
                >
                  {t('buttons.btn_update')}
                </AppButton>
                <AppButton
                  loading={requestLoading}
                  size="lg"
                  style={{ fontSize: 14, width: 'auto', display: 'unset', flexDirection: 'unset' }}
                  color={PALETTE_COLOR.MAIN_BLUE}
                  bgcolor={PALETTE_COLOR.MAIN_WHITE}
                  bordercolor={PALETTE_COLOR.TOP_GRAY}
                  bghovercolor={PALETTE_COLOR.MAIN_BLUE}
                  borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                  onClick={handleclose}
                  title={t('buttons.btn_cancel')}
                >
                  {t('buttons.btn_cancel')}
                </AppButton>
              </span>
              {showErrorMessage.show && (
                <CustomizedSnackbar
                  show={showErrorMessage.show}
                  type={showErrorMessage.type}
                  title={showErrorMessage.title}
                  message={showErrorMessage.message}
                />
              )}
            </StyleButtons>
          </Form>
          {/* Change e-mail request */}
          <AppModal
            width={500}
            styleModal={{ padding: '30px 5px 30px 5px', width: '600px' }}
            styles={{}}
            open={openEmailConfirmation}
            handleclose={handleChangeEmailModal}
            title={''}
            boxshadow={'none'}
            inside={1}
          >
            <AppModalStatus
              actionIcon={changeEmailModalData.actionIcon}
              title={`${t('Profile.change_personal.changing_email')}`}
              subtitle={''}
              subtitleColor={changeEmailModalData.subtitleColor}
              subtitleWeight={changeEmailModalData.subtitleWeight}
              bgcolor={changeEmailModalData.bgcolor}
              bordercolor={changeEmailModalData.bordercolor}
              borderhovercolor={changeEmailModalData.borderhovercolor}
              bghovercolor={changeEmailModalData.bghovercolor}
              boxShadow={changeEmailModalData.boxShadow}
              buttonText={changeEmailModalData.buttonText}
              subject={changeEmailModalData.subtitle}
              subjectColor={changeEmailModalData.subjectColor}
              subjectWeight={changeEmailModalData.subjectWeight}
              buttonAction={editEmployee}
              isLoading={loading}
            />
          </AppModal>
        </div>
      )}
    </Formik>
  );
};
